import { useContext, useEffect } from 'react';
import { LineaEsperaContext } from '@/context';
import { ClienteEsperaList } from '@/components/ClienteEsperaList';

/* DEPRECADO */

const Component = () => {
    const { clientesEspera, resetAsignarMesa, nFiltroPor } = useContext(LineaEsperaContext);

    useEffect(() => {
        resetAsignarMesa();
    }, [resetAsignarMesa]);

    return (
        <div className="flex h-full w-full flex-col flex-wrap items-stretch content-start gap-6">
            <ClienteEsperaList 
                clientesEspera={clientesEspera} 
                nFiltroPor={nFiltroPor}
            />
        </div>
    );
};

export {
    Component,
    Component as ListaEspera
}