import { AuthProvider } from '@/context';
import { NextUIProvider } from '@nextui-org/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Outlet, useNavigate } from 'react-router-dom';
import { Toaster } from 'sonner';

// Create a client
const queryClient = new QueryClient({
    defaultOptions: {
        mutations: {
            retry: 3
        }
    }
});

const Root = () => {
    const navigate = useNavigate();

    return (
        <QueryClientProvider client={queryClient}>
            <NextUIProvider navigate={navigate}>
                <Toaster position='top-right' richColors />
                <AuthProvider>
                    <Outlet />
                </AuthProvider>
            </NextUIProvider>
        </QueryClientProvider>
    );
};

export default Root;
