import { useWatch } from 'react-hook-form';
import { Pen, Phone, Pin, UserIcon } from '@/assets';
import { useContext } from 'react';
import { LineaEsperaContext } from '@/context';
import {
    BaseFormModal,
    NumberInputWithSteppers,
    PhoneNumberInput,
    SelectInput,
    TextInput,
    TextareaInput,
} from 'nextui-utils';
import { SwitchInput } from 'nextui-utils';
import { FORM_STATE } from '@/types';

interface props {
    isOpen: boolean;
    onOpenChange: () => void;
}

export const ModalFormCliente = ({ isOpen, onOpenChange }: props) => {
    const {
        controlFormCliente,
        handleSubmitCliente,
        isSubmittingCliente,
        isLoadingConfiguracion,
        preferencias,
        closeFormCliente,
    } = useContext(LineaEsperaContext);

    const { bRegistro, formState, nPersonas } = useWatch({
        control: controlFormCliente,
    });

    const isUpdatingCliente = formState === FORM_STATE.UPDATE;

    return (
        <BaseFormModal
            isOpen={isOpen}
            onOpenChange={(isOpen) => {
                !isOpen && closeFormCliente();

                onOpenChange();
            }}
            title={isUpdatingCliente ? "Modificar un cliente" : "Registrar un cliente"}
            handleSubmit={handleSubmitCliente}
            isSubmitting={isSubmittingCliente}
            ModalProps={{
                classNames: {
                    body: 'grid gap-4 grid-cols-1 sm:grid-cols-2',
                }
            }}
            SubmitButton={{
                children: isUpdatingCliente ? 'Guardar' : 'Agregar'
            }}
        >
            <SwitchInput 
                control={controlFormCliente} 
                name="bRegistro" 
                label="Cliente con Registro" 
            />

            <NumberInputWithSteppers
                control={controlFormCliente}
                name="nPersonas"
                label="Cantidad de Personas"
                max={99}
                min={1}
                required
            />

            <TextInput
                control={controlFormCliente}
                name="cNombre"
                disabled={!bRegistro}
                label="Nombre"
                max={20}
                min={3}
                startContent={<UserIcon />}
                required={bRegistro}
                autoFocus={nPersonas !== '1'}
            />

            <PhoneNumberInput
                control={controlFormCliente}
                name="cTelefono"
                disabled={!bRegistro}
                label="Telefono"
                startContent={<Phone />}
                required={false}
            />

            <SelectInput
                control={controlFormCliente}
                label="Preferencia"
                placeholder="Seleccione una zona"
                labelPlacement="outside"
                name="aPreferencia"
                isLoading={isLoadingConfiguracion}
                disabled={!bRegistro}
                required={bRegistro}
                startContent={<Pin />}
                items={preferencias}
                maxSelect={1}
            />

            <SwitchInput
                control={controlFormCliente}
                name="bClienteEspecial"
                label="Cliente Especial"
                disabled={!bRegistro}
            />

            <div className="sm:col-span-2">
                <TextareaInput
                    control={controlFormCliente}
                    name="cNotas"
                    disabled={!bRegistro}
                    label="Notas"
                    maxRows={5}
                    minRows={3}
                    required={false}
                    min={3}
                    max={500}
                    startContent={<Pen />}
                />
            </div>
        </BaseFormModal>
    );
};
