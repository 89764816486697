import {
    AreasRestaurante,
    ClienteEspera,
    ConfiguracionesRespuesta,
    FILTROS_LISTA,
    IndicadoresNav,
    MesasRespuesta,
    QUERY_KEYS,
    SelectValues,
} from '@/types';
import { useQuery } from '@tanstack/react-query';
import { API_URL, simpleFetch } from '@/utils/Fetch';
import { compararMesasRespuestas } from '@/utils';
import { useState } from 'react';

interface props {
    nFiltroPor: FILTROS_LISTA;
}

export const useLineaEsperaData = ({ nFiltroPor }: props) => {
    const { data: clientesEspera, isPending: isLoadingClientesEspera } = useQuery<ClienteEspera[]>({
        queryKey: [QUERY_KEYS.CLIENTES_ESPERA, { nFiltroPor }],
        queryFn: async () => {
            const response = await simpleFetch<ClienteEspera[]>({
                method: 'POST',
                url: `${API_URL}/Obten/Clientes`,
                params: {
                    nFiltroPor,
                },
            });

            if (!response.success) {
                throw response.data;
            }

            return response.data;
        },
        refetchInterval: 60000,
    });

    const { data: indicadoresNavbar, isPending: isLoadingIndicadores } = useQuery<IndicadoresNav>({
        queryKey: [QUERY_KEYS.INDICADORES_NAV],
        queryFn: async () => {
            const response = await simpleFetch<IndicadoresNav>({
                method: 'GET',
                url: `${API_URL}/Obten/Indicadores`,
            });

            if (!response.success) {
                throw response.data;
            }

            return response.data;
        },
        refetchInterval: 60000,
    });

    const { data: configuraciones, isPending: isLoadingConfiguracion } = useQuery({
        queryKey: [QUERY_KEYS.CONFIGURACIONES],
        staleTime: Infinity,
        queryFn: async () => {
            const response = await simpleFetch<ConfiguracionesRespuesta>({
                method: 'GET',
                url: `${API_URL}/Obten/Configuraciones`,
            });

            if (!response.success) {
                throw 'Sucedió un error';
            }

            const preferencias = response.data.Preferencias.map((preferencia) => ({
                label: preferencia.cPreferencia,
                value: preferencia.nPreferencia,
            })) as SelectValues[];

            const estatusOcupado = response.data.EstatusMesas.find((estatus) => estatus.bOcupadoColor);

            return {
                estatus: response.data.EstatusMesas,
                estatusOcupado,
                ColoresCapacidad: [
                    ...response.data.ColoresCapacidad.sort((a, b) => b.nMinimoCapacidad - a.nMinimoCapacidad),
                ],
                preferencias,
            };
        },
    });
    const { preferencias, estatus, ColoresCapacidad, estatusOcupado } = configuraciones ?? {
        ColoresCapacidad: undefined,
        estatus: undefined,
        preferencias: undefined,
        estatusOcupado: undefined
    };

    const { data: areasRestaurante, isPending: isLoadingAreas } = useQuery<AreasRestaurante[]>({
        queryKey: [QUERY_KEYS.AREAS_RESTAURANTE],
        staleTime: Infinity,
        queryFn: async () => {
            const response = await simpleFetch<AreasRestaurante[]>({
                method: 'GET',
                url: `${API_URL}/Obten/Areas`,
                params: {},
            });

            if (!response.success) {
                throw response.data;
            }

            return response.data;
        },
    });

    const [shouldReloadPanel, setShouldReloadPanel] = useState(false);
    const { data: mesasRestaurante, isPending: isLoadingMesas } = useQuery<MesasRespuesta>({
        queryKey: [QUERY_KEYS.MESAS_RESTAURANTE],
        queryFn: async () => {
            const response = await simpleFetch<MesasRespuesta>({
                method: 'GET',
                url: `${API_URL}/Obten/Mesas`,
                params: '0',
            });

            if (!response.success) {
                throw response.data;
            }

            const diferencias = compararMesasRespuestas(mesasRestaurante ?? {}, response.data);

            if (Object.keys(diferencias).length) {
                setShouldReloadPanel(true);
                setTimeout(() => setShouldReloadPanel(false), 16);
            }

            return { ...response.data };
        },
        refetchInterval: 10000,
    });

    return {
        clientesEspera,
        isLoadingClientesEspera,

        indicadoresNavbar,
        isLoadingIndicadores,

        areasRestaurante,
        isLoadingAreas,

        mesasRestaurante,
        isLoadingMesas,
        shouldReloadPanel,

        preferencias, 
        estatus,
        ColoresCapacidad, 
        estatusOcupado,
        isLoadingConfiguracion,
    };
};
