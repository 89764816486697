import { useContext } from 'react';
import { Card, CardBody, Skeleton, Tab, Tabs } from '@nextui-org/react';
import { useWatch } from 'react-hook-form';
import { LineaEsperaContext } from '@/context';
import { PlantaRestaurante } from '@/components/PlantaRestaurante';
import { PlantaLoading } from '@/components/Loading/PlantaLoading';

const Component = () => {
    const {
        controlAsignarMesa,
        resetFieldAsignarMesa,
        areasRestaurante,
        isLoadingAreas,
        mesasRestaurante,
        isLoadingMesas,
        setValueAsignarMesa,
        shouldReloadPanel,
    } = useContext(LineaEsperaContext);

    const { planta } = useWatch({ control: controlAsignarMesa });
    const isLoading = isLoadingAreas || isLoadingMesas;

    return (
        <div className="flex h-full w-full flex-row content-start items-stretch">
            <Card className="flex-grow rounded-none">
                <CardBody className="p-0">
                    {isLoading || shouldReloadPanel ? (
                        <Tabs
                            key="plantasRestauranteLoading"
                            variant="underlined"
                            classNames={{
                                panel: 'h-full max-h-contentHeight py-0 px-0 rounded-none',
                                tabList: 'p-4 gap-4 flex',
                                tabContent: 'font-bold text-sm',
                                tab: 'p-0 data-[disabled=true]:opacity-100 data-[disabled=true]:cursor-auto',
                                cursor: 'w-full bottom-1 hidden',
                            }}
                            size="lg"
                            aria-label="Plantas de restaurante"
                        >
                            <Tab
                                isDisabled
                                title={
                                    <div className="flex gap-4">
                                        <Skeleton className="h-9 w-20 rounded-md" />{' '}
                                        <Skeleton className="h-9 w-20 rounded-md" />
                                    </div>
                                }
                            >
                                <PlantaLoading />
                            </Tab>
                        </Tabs>
                    ) : (
                        <Tabs
                            selectedKey={planta ? planta.toString() : ''}
                            onSelectionChange={(key) => {
                                setValueAsignarMesa('planta', parseInt(key?.toString()));
                                resetFieldAsignarMesa('mesas');
                            }}
                            key="plantasRestaurante"
                            variant="underlined"
                            classNames={{
                                panel: 'h-full max-h-contentHeight py-0 px-0 rounded-none',
                                tabList: 'p-4 gap-4 flex',
                                tabContent: 'font-bold text-sm',
                                tab: 'p-0',
                                cursor: 'w-full bottom-1',
                            }}
                            size="lg"
                            aria-label="Plantas de restaurante"
                            items={Array.isArray(areasRestaurante) ? areasRestaurante : []}
                        >
                            {(item) => (
                                <Tab key={item.nArea} title={item.cArea}>
                                    <PlantaRestaurante
                                        secciones={mesasRestaurante ? mesasRestaurante[item.nArea.toString()] : []}
                                    />
                                </Tab>
                            )}
                        </Tabs>
                    )}
                </CardBody>
            </Card>
        </div>
    );
};

export {
    Component,
    Component as AsignarMesa
}