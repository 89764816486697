import { cn } from '@nextui-org/react';
import { Children } from '@/types';

interface props {
    children: Children;
    className?: string;
}

export const IconWrapper = ({ children, className }: props) => {
    return <div className={cn(className, 'flex h-7 w-7 items-center justify-center rounded-small')}>{children}</div>;
};

