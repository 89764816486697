import { useLocalStorage } from '@/hooks/useLocalStorage';
import { SSO_API_URL, customFetch, handleRedirectSSO } from '@/utils/Auth';
import { useState, createContext, useEffect } from 'react';

interface AuthType {
	isLogged: boolean | null;
	handleValidarOTP: (cOTP: string, cDeviceToken: string) => Promise<boolean>;
	handleValidarSesion: () => Promise<boolean>;
	Authorization: string;
	userData: userData | null
}

interface userData {
	nUsuario: number,
	cLogin: string,
	cNombre: string,
	cDeviceToken: string
}

export const AuthContext = createContext({} as AuthType);

// Se manejan estados globales de la info de usuario
export const AuthProvider = ({ children }: { children: JSX.Element }) => {
	const [isLogged, setIsLogged] = useState<boolean | null>(null);
	const [userData, setUserData] = useState<userData | null>(null);
	const TEN_MINUTES = 600000;
	const [Authorization, setAuthorization] = useLocalStorage('SSO_CREDENTIALS', '', TEN_MINUTES);

	const handleValidarOTP = async (cOTP: string, cDeviceToken: string) => {
		const { response } = await customFetch({ 
			url: `${SSO_API_URL}/Valida/OTP`, 
			method: 'POST', 
			dataParams: {
				cOTP, 
				cDeviceToken
			}
		});

		setIsLogged(response.Success);
		setAuthorization(response.Success ? `Bearer ${response.Data}` : '')

		return response.Success;
	};

	const handleValidarSesion = async () => {
		const { response, ...meta } = await customFetch<userData>({ 
			url: `${SSO_API_URL}/Valida/Sesion`, 
			method: 'POST', 
			headers: {
				Authorization
			},
			dataParams: {}
		});


		setIsLogged(response.Success);
		setUserData(response.Success ? response.Data as userData : null);
		response.Success && 'headers' in meta && meta.status === 210 && setAuthorization(`Bearer ${meta?.headers.get('RefreshedToken')}`);

		return response.Success;
	};

	useEffect(() => {
		if (isLogged === false) {
			handleRedirectSSO();
		}
	}, [isLogged]);

	return (
		<AuthContext.Provider
			value={{
				isLogged,
				handleValidarOTP,
				handleValidarSesion,
				Authorization,
				userData
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
