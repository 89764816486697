import { useContext, useEffect, useMemo } from 'react';
import { MesaRestaurante } from '@/types';
import { Listbox, ListboxItem, ListboxSection, Popover, PopoverContent, PopoverTrigger } from '@nextui-org/react';
import { useLongPress } from '@/hooks/useLongPress';
import { LineaEsperaContext } from '@/context';
import { Controller } from 'react-hook-form';
import { CheckboxChecked, CircleUndo, CircleUser } from '@/assets/icons';
import { convertToAMPM } from '@/utils';
import { IconWrapper } from '../IconWrapper';

export const MesaButton = (MesaInfo: MesaRestaurante) => {
    const { nMesa } = MesaInfo;

    const { controlAsignarMesa } = useContext(LineaEsperaContext);

    return (
        <Controller
            control={controlAsignarMesa}
            name="mesas"
            rules={{
                required: true,
            }}
            render={({ field: { value, onChange } }) => {
                const isSelected = value.find((mesa) => mesa === nMesa.toString());
                const handleSeleccionarMesa = ({ nMesa }: MesaRestaurante) => {
                    const mesaSeleccionada = nMesa.toString();
                    if (value.find((val) => val === mesaSeleccionada)) {
                        onChange(value.filter((seleccionado) => seleccionado !== mesaSeleccionada));
                    } else {
                        onChange([...value, mesaSeleccionada]);
                    }
                };

                return (
                    <Boton
                        handleSeleccionarMesa={handleSeleccionarMesa}
                        MesaInfo={MesaInfo}
                        isSelected={!!isSelected}
                    />
                );
            }}
        />
    );
};

interface BotonProps {
    handleSeleccionarMesa: (newValue: MesaRestaurante) => void;
    MesaInfo: MesaRestaurante;
    isSelected: boolean;
}

const Boton = ({ handleSeleccionarMesa, MesaInfo, isSelected }: BotonProps) => {
    const { nEstatusMesa, nCapacidadComensales, cCodigoMesa, nMesa, cCliente, cHoraAsignacion } = MesaInfo;
    const { isPressed, isTouchedBeforeTimeout, handlers } = useLongPress({ closeDelay: 1500, timeout: 800 });

    const { estatus, ColoresCapacidad, estatusOcupado, handleLiberarMesa, handleDeshacerAsignacion } =
        useContext(LineaEsperaContext);

    const estatusMesa = useMemo(() => estatus?.find((val) => val.nEstatus === nEstatusMesa), [nEstatusMesa, estatus]);
    const ColorCapacidad = useMemo(
        () => ColoresCapacidad?.find((color) => color.nMinimoCapacidad <= nCapacidadComensales),
        [nCapacidadComensales, ColoresCapacidad],
    );
    const buttonHandlers = estatusMesa?.bDisponible ? handlers : {};

    useEffect(() => {
        if (isTouchedBeforeTimeout && estatusMesa?.bDisponible) {
            handleSeleccionarMesa(MesaInfo);
        }
    }, [isTouchedBeforeTimeout]);

    return (
        <Popover
            isOpen={estatusMesa?.bDisponible ? isPressed : undefined}
            classNames={{ content: 'px-0 py-0 overflow-hidden' }}
        >
            <PopoverTrigger>
                <button {...buttonHandlers} type="button" className="MESA_BUTTON relative cursor-pointer outline-none">
                    <span
                        className={`flex size-12 items-center justify-center rounded-md font-bold transition-all duration-300 ease-in-out ${isSelected ? 'scale-115 border-3 border-zinc-800 text-lg' : ''}`}
                        style={{
                            background: isSelected
                                ? '#FFF'
                                : estatusMesa?.bDisponible
                                ? ColorCapacidad?.cColorCapacidad
                                : `linear-gradient(180deg, ${estatusOcupado?.cColorEstatus} 0%, ${estatusOcupado?.cColorEstatus} 85%, ${ColorCapacidad?.cColorCapacidad} 85%, ${ColorCapacidad?.cColorCapacidad} 100%)`,
                            color: isSelected ? ColorCapacidad?.cColorCapacidad : '#FFF',
                        }}
                    >
                        {cCodigoMesa}
                    </span>

                    {estatusMesa?.bWarning && (
                        <span className="absolute bottom-9 left-9 size-4">
                            <span
                                className="absolute bottom-[-2px] size-full animate-ping rounded-full opacity-75"
                                style={{ backgroundColor: estatusMesa.cColorEstatus }}
                            />
                            <span
                                className="relative inline-flex size-4 rounded-full"
                                style={{ backgroundColor: estatusMesa.cColorEstatus }}
                            />
                        </span>
                    )}

                    {
                        MesaInfo.nID ? (
                            <span className="absolute bottom-9 right-9 width-6 p-0.5 text-tiny bg-default-200 text-default-800 rounded-small">
                                {MesaInfo.nID.substring(MesaInfo.nID.length - 2)}
                            </span>
                        ) : <></>
                    }
                </button>
            </PopoverTrigger>
            <PopoverContent>
                <div
                    className="flex min-h-24 flex-col items-center justify-center gap-2 p-2"
                    style={{
                        backgroundColor: estatusMesa?.bDisponible ? ColorCapacidad?.cColorCapacidad : '',
                    }}
                >
                    {!estatusMesa?.bDisponible && (
                        <div className="flex w-full items-center justify-between gap-1">
                            <span>{convertToAMPM(cHoraAsignacion)}</span>

                            <div className="flex items-center gap-1">
                                <CircleUser width={18} height={18} />

                                <span>{cCliente}</span>
                            </div>
                        </div>
                    )}

                    <div className={`flex gap-2 ${estatusMesa?.bDisponible ? 'text-white' : ''}`}>
                        <div
                            className="flex size-10 items-center justify-center rounded-md font-bold text-white transition-all duration-300 ease-in-out"
                            style={{
                                backgroundColor: ColorCapacidad?.cColorCapacidad,
                            }}
                        >
                            {cCodigoMesa}
                        </div>

                        <div className="flex flex-col">
                            <p
                                className="text-base font-bold"
                                style={{ color: !estatusMesa?.bDisponible ? estatusMesa?.cColorEstatus : '' }}
                            >
                                {estatusMesa?.cEstatus}
                            </p>
                            <p className={`text-small ${estatusMesa?.bDisponible ? '' : 'text-zinc-800'}`}>
                                Mesa para {nCapacidadComensales} persona{nCapacidadComensales !== 1 ? 's' : ''}
                            </p>
                        </div>
                    </div>

                    {!estatusMesa?.bDisponible && (
                        <Listbox classNames={{ base: 'p-0' }} aria-label="Listado de Acciones">
                            <ListboxSection classNames={{ base: 'mb-0' }} title="Acciones">
                                <ListboxItem
                                    startContent={
                                        <IconWrapper className="bg-success-100 text-success-600">
                                            <CheckboxChecked width={20} height={20} />
                                        </IconWrapper>
                                    }
                                    onClick={() => handleLiberarMesa(nMesa)}
                                    key="liberarMesa"
                                >
                                    Liberar Mesa
                                </ListboxItem>
                                <ListboxItem
                                    startContent={
                                        <IconWrapper className="bg-primary-100 text-primary-600">
                                            <CircleUndo width={20} height={20} />
                                        </IconWrapper>
                                    }
                                    onClick={() => handleDeshacerAsignacion(nMesa)}
                                    key="deshacerAsignacion"
                                >
                                    Deshacer Asignación
                                </ListboxItem>
                            </ListboxSection>
                        </Listbox>
                    )}
                </div>
            </PopoverContent>
        </Popover>
    );
};
