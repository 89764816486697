import { useContext, useMemo } from 'react';
import { LineaEsperaContext } from '@/context';

interface props {
    personas: number;
    orden: string;
}

export const Indicator = ({ personas, orden }: props) => {
    const { ColoresCapacidad } = useContext(LineaEsperaContext);

    const Color = useMemo(
        () => ColoresCapacidad?.find((color) => color.nMinimoCapacidad <= personas),
        [personas, ColoresCapacidad],
    );

    return (
        <div style={{ backgroundColor: Color?.cColorCapacidad }} className='rounded-md flex min-h-8 min-w-8 items-center justify-center'>
            <p className="font-bold leading-none text-white">{orden}</p>
        </div>
    );
};
