import { NavbarItem, Popover, PopoverContent, PopoverTrigger } from '@nextui-org/react';

interface props {
    icon: JSX.Element;
    data?: string | number;
    description: string;
    offset?: number;
}

export const NavItemIcon = ({ icon, data, description, offset }: props) => {
    return (
        <Popover placement="bottom" offset={offset} color="foreground">
            <PopoverTrigger>
                <NavbarItem className="flex cursor-pointer flex-row content-center gap-2">
                    {icon}
                    <p className="m-auto font-bold text-inherit">{data}</p>
                </NavbarItem>
            </PopoverTrigger>
            <PopoverContent>
                <div className="px-1 py-2">
                    <div className="text-small font-bold">{description}</div>
                </div>
            </PopoverContent>
        </Popover>
    );
};
