import { useState, useEffect } from 'react';

export const useWindowSize = () => {
    const isWindowClient = typeof window === 'object';

    const [widthSize, setWidthSize] = useState(isWindowClient ? window.innerWidth : undefined);
    const [heightSize, setHeightSize] = useState(isWindowClient ? window.innerHeight : undefined);

    useEffect(() => {
        function setSize() {
            setWidthSize(window.innerWidth);
			setHeightSize(window.innerHeight);
        }

        if (isWindowClient) {
            window.addEventListener('resize', setSize);

            return () => window.removeEventListener('resize', setSize);
        }
    }, [isWindowClient, setWidthSize]);

    return {
		widthSize,
		heightSize
	};
}
