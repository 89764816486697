import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';

import { DisponibilidadInfo } from './DisponibilidadInfo';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, CardHeader } from '@nextui-org/react';
import { UserAdd } from '@/assets';
import { LineaEsperaContext } from '@/context';
import { MesaButton } from '../MesaButton';
import { SeccionRestaurante } from '@/types';
import { useScreenOrientation } from '@/hooks/useScreenOrientation';
import { useWindowSize } from '@/hooks/useWindowSize';

interface props {
    secciones: SeccionRestaurante[];
}

export const PlantaRestaurante = ({ secciones }: props) => {
    const orientation = useScreenOrientation();
    const windowSize = useWindowSize();
    
    const {
        toggleModalAsignarMesa,
        fieldValuesAsignarMesa: { mesas },
        isOpenSideBar,
        initCreateFormCliente
    } = useContext(LineaEsperaContext);

    const wrapperRef = useRef<ReactZoomPanPinchRef | null>(null);
    const mesasContainer = useRef<HTMLDivElement>(null);
    const [scale, setScale] = useState(1);

    const adjustScale = () => {
        const wrapperComp = wrapperRef?.current?.instance.wrapperComponent;

        if (!mesasContainer.current || !wrapperComp) {
            return;
        }

        wrapperRef.current?.centerView?.();
        wrapperRef.current?.resetTransform?.();

        const widthRatio = (wrapperComp.offsetWidth - 32) / mesasContainer.current.offsetWidth;
        const heightRatio = (wrapperComp.offsetHeight - 32) / mesasContainer.current.offsetHeight;

        setScale(Math.min(widthRatio, heightRatio));
    };

    useEffect(() => {
        adjustScale();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            adjustScale();
        }, 300);
    }, [isOpenSideBar, orientation, windowSize?.heightSize, windowSize?.widthSize]);

    return (
        <div className="relative h-full bg-zinc-100">
            <TransformWrapper
                minScale={0.9}
                maxScale={5}
                ref={wrapperRef}
                doubleClick={{
                    excluded: ['MESA_BUTTON']
                }}
                panning={{
                    excluded: ['MESA_BUTTON']
                }}
                pinch={{
                    excluded: ['MESA_BUTTON']
                }}
                centerZoomedOut
                centerOnInit
                limitToBounds={false}
            >
                <TransformComponent
                    wrapperClass="!w-full !h-full relative"
                    contentClass={`!h-full !w-full justify-center items-center`}
                >
                    <div
                        style={{ transform: `scale(${scale})` }}
                        className="flex h-full w-full items-center justify-center"
                    >
                        <div ref={mesasContainer} className="flex flex-wrap justify-start gap-6">
                            {secciones.map((seccion, keyC) => (
                                <Card key={keyC}>
                                    <CardHeader className="pb-0">
                                        <h4 className="text-large font-bold">{seccion.cSeccion}</h4>
                                    </CardHeader>
                                    <CardBody className={`grid grid-flow-col grid-rows-3 content-start gap-3`}>
                                        {seccion.mesas.map((mesa, key) => (
                                            <div key={key}>
                                                <MesaButton {...mesa} />
                                            </div>
                                        ))}
                                    </CardBody>
                                </Card>
                            ))}
                        </div>
                    </div>
                </TransformComponent>
            </TransformWrapper>

            <DisponibilidadInfo secciones={secciones} />

            <Button
                aria-label="Asignar Mesa"
                color="secondary"
                size="md"
                radius="md"
                className="absolute bottom-4 right-1/2 w-32 translate-x-16"
                variant="shadow"
                isDisabled={mesas.length === 0}
                onPress={toggleModalAsignarMesa}
            >
                Asignar Mesa
            </Button>

            <Button
                isIconOnly
                aria-label="Agregar Cliente"
                color="success"
                size="lg"
                radius="lg"
                className="absolute bottom-4 right-4"
                variant="shadow"
                onPress={() => initCreateFormCliente()}
            >
                <UserAdd width={26} height={26} />
            </Button>

            <Button
                isIconOnly
                aria-label="Agregar Cliente"
                color="success"
                size="md"
                radius="md"
                className="absolute bottom-20 right-16"
                variant="shadow"
                onPress={() => initCreateFormCliente('2')}
            >
                <span className='font-black text-large'>2</span>
            </Button>

            <Button
                isIconOnly
                aria-label="Agregar Cliente"
                color="success"
                size="md"
                radius="md"
                className="absolute bottom-20 right-4"
                variant="shadow"
                onPress={() => initCreateFormCliente('3')}
            >
                <span className='font-black text-large'>3</span>
            </Button>

            <Button
                isIconOnly
                aria-label="Agregar Cliente"
                color="success"
                size="md"
                radius="md"
                className="absolute bottom-[136px] right-16"
                variant="shadow"
                onPress={() => initCreateFormCliente('4')}
            >
                <span className='font-black text-large'>4</span>
            </Button>

            <Button
                isIconOnly
                aria-label="Agregar Cliente"
                color="success"
                size="md"
                radius="md"
                className="absolute bottom-[136px] right-4"
                variant="shadow"
                onPress={() => initCreateFormCliente('5')}
            >
                <span className='font-black text-large'>5</span>
            </Button>

            <Button
                isIconOnly
                aria-label="Agregar Cliente"
                color="success"
                size="md"
                radius="md"
                className="absolute bottom-48 right-16"
                variant="shadow"
                onPress={() => initCreateFormCliente('6')}
            >
                <span className='font-black text-large'>6</span>
            </Button>

            <Button
                isIconOnly
                aria-label="Agregar Cliente"
                color="success"
                size="md"
                radius="md"
                className="absolute bottom-48 right-4"
                variant="shadow"
                onPress={() => initCreateFormCliente('7')}
            >
                <span className='font-black text-large'>7</span>
            </Button>
        </div>
    );
};
