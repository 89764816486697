import { FILTROS_LISTA } from '@/types';
import { useDisclosure } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { useLocalStorage } from './useLocalStorage';

interface LayoutSettings {
    sideBar: boolean;
    agregarCliente: boolean;
    asignarMesa: boolean;
    filtroCliente: FILTROS_LISTA[];
}

export const useLayout = () => {
    const [layoutSettings, setLayoutSettings] = useLocalStorage<LayoutSettings>('LAYOUT_SETTINGS', {
        sideBar: true,
        agregarCliente: false,
        asignarMesa: false,
        filtroCliente: [FILTROS_LISTA.EN_ESPERA],
    });

    const {
        isOpen: isOpenSideBar,
        onClose: closeSideBar,
        onOpen: openSideBar,
        onOpenChange: toggleSideBar,
    } = useDisclosure({
        defaultOpen: layoutSettings.sideBar,
    });

    const {
        isOpen: isOpenModalFormCliente,
        onClose: closeModalFormCliente,
        onOpen: openModalFormCliente,
        onOpenChange: toggleModalFormCliente,
    } = useDisclosure({
        defaultOpen: layoutSettings.agregarCliente,
    });

    const {
        isOpen: isOpenModalAsignarMesa,
        onClose: closeModalAsignarMesa,
        onOpen: openModalAsignarMesa,
        onOpenChange: toggleModalAsignarMesa,
    } = useDisclosure({
        defaultOpen: layoutSettings.asignarMesa,
    });

    const [[nFiltroPor], setNFiltroPor] = useState<FILTROS_LISTA[]>(layoutSettings.filtroCliente);

    useEffect(() => {
        setLayoutSettings({
            agregarCliente: isOpenModalFormCliente,
            asignarMesa: isOpenModalAsignarMesa,
            filtroCliente: [nFiltroPor],
            sideBar: isOpenSideBar,
        });
    }, [isOpenModalFormCliente, isOpenModalAsignarMesa, isOpenSideBar, nFiltroPor]);

    return {
        isOpenSideBar,
        closeSideBar,
        openSideBar,
        toggleSideBar,

        isOpenModalFormCliente,
        closeModalFormCliente,
        openModalFormCliente,
        toggleModalFormCliente,

        isOpenModalAsignarMesa,
        closeModalAsignarMesa,
        openModalAsignarMesa,
        toggleModalAsignarMesa,

        nFiltroPor,
        setNFiltroPor,
    };
};
