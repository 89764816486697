import { Card, CardBody, Skeleton } from "@nextui-org/react";

export const ClientesEsperaLoading = () => {
    return (
        <Card
            className='w-full max-w-clienteItem cursor-pointer select-none rounded-none'
        >
            <CardBody className="flex flex-row items-center gap-3 text-center text-sm font-medium">
                <Skeleton className="rounded-md min-h-8 min-w-8 w-full" />
            </CardBody>
        </Card>
    );
};
