import { UseMutateFunction } from '@tanstack/react-query';
import { Control, UseFormReset, UseFormResetField, UseFormSetValue } from 'react-hook-form';

export type Children = JSX.Element | JSX.Element[];

export interface ContextProps {
    children: Children;
}

export interface ContextoMesas {
    clientesEspera: ClienteEspera[] | undefined;
    isLoadingClientesEspera: boolean;
    controlAsignarMesa: Control<FormAsignarMesa, unknown>;
    handleSubmitAsignarMesa: React.FormEventHandler<HTMLFormElement>;
    resetAsignarMesa: UseFormReset<FormAsignarMesa>;
    setValueAsignarMesa: UseFormSetValue<FormAsignarMesa>
    resetFieldAsignarMesa: UseFormResetField<FormAsignarMesa>;
    isOpenModalAsignarMesa: boolean;

    openModalAsignarMesa: () => void;
    toggleModalAsignarMesa: () => void;
    fieldValuesAsignarMesa: FormAsignarMesa;

    isOpenSideBar: boolean;

    nFiltroPor: FILTROS_LISTA;
    setNFiltroPor: React.Dispatch<React.SetStateAction<FILTROS_LISTA[]>>;

    handleClienteNoEncontrado: UseMutateFunction<string, string[], string, unknown>;
    handleEliminarCliente: UseMutateFunction<string, string[], string, unknown>;
    handleLiberarMesa: UseMutateFunction<string, string[], number, unknown>;
    handleMoverClienteEspera: UseMutateFunction<string, string[], string, unknown>
    handleDeshacerAsignacion: UseMutateFunction<string, string[], number, unknown>

    isSubmittingAsignarMesa: boolean;

    preferencias: SelectValues[] | undefined;
    estatus: EstatusMesa[] | undefined;
    ColoresCapacidad: ColorCapacidad[] | undefined;
    estatusOcupado: EstatusMesa | undefined

    isOpenModalFormCliente: boolean
    openModalFormCliente: () => void;
    resetFormCliente: UseFormReset<ClienteForm>
    handleSubmitCliente: (e?: React.BaseSyntheticEvent<object, unknown, unknown> | undefined) => Promise<void>;
    controlFormCliente: Control<ClienteForm, unknown>;
    isSubmittingCliente: boolean;
    isLoadingConfiguracion: boolean;
    responseAsignarMesa?: AsignaMesaResponse | string

    isLoadingAreas: boolean;
    areasRestaurante?: AreasRestaurante[];

    mesasRestaurante?: MesasRespuesta;
    isLoadingMesas: boolean;
    shouldReloadPanel: boolean;

    closeFormCliente: () => void
    isFetchingClienteUpdate: boolean
    initCreateFormCliente: (nPersonas?: string) => void
    initUpdateFormCliente: (nID: ClienteForm["nID"]) => void
}

export interface ClienteForm {
    bRegistro: boolean;
    bClienteEspecial: boolean;
    nPersonas: string;
    cNombre: string;
    cTelefono: string;
    aPreferencia: string[] | [undefined];
    cNotas: string;
    nID: ClienteEspera['nID']
    formState: FORM_STATE
}

export enum FORM_STATE {
    CREATE,
    UPDATE,
    DELETE,
    READ
}

export interface Opcion {
    description: string;
    action?: () => void;
    key?: string;
}

export interface EditarClienteEspera extends ClienteEspera {
    bRegistro: boolean
    aPreferencia: string[] | [undefined]
}

export interface ClienteEspera {
    nID: string;
    cNombre: string;
    cTelefono: string;
    nPersonas: number;
    cPreferencia: string;
    cNotas: string;
    bSinRegistro: boolean;
    bClienteEspecial: boolean;
    dFecha_Registro: string;
    cHoraRegistro: string;
}

export interface AreasRestaurante {
    nArea: number;
    cArea: string;
}

export interface MesaRestaurante {
    nSeccion: number;
    nMesa: number;
    cCodigoMesa: string;
    nEstatusMesa: number;
    nCapacidadComensales: number;
    cHoraAsignacion: string,
    cCliente: string,
    nComensales: number
    nID: ClienteEspera['nID']
}

export interface SeccionRestaurante {
    nSeccion: number;
    cSeccion: string;
    mesas: MesaRestaurante[];
}

export type MesasRespuesta = Record<string, SeccionRestaurante[]>;

export interface IndicadoresNav {
    nPersonas: number;
    nGrupos: number;
    cTiempoPromedioEspera: string;
    nPorcOcupacionMesas: number;
    nPorcOcupacionPersonas: number;
    nGruposEspera: number;
}

export interface FormAsignarMesa {
    cliente: [string] | null;
    mesas: string[];
    planta: number | null;
    bConfirmado: boolean
}

export interface Item {
    value: string | number;
    label: string;
}

export enum PagesRoutes {
    LISTA_ESPERA = '/lista-espera',
    ASIGNAR_MESAS = '/asignacion-mesas',
    ValidarOTP = '/ValidarOTP',
}

export interface CapacidadMesaPlanta {
    nCapacidad: number;
    nLibres: number;
}

export interface DistribucionMesasPlanta {
    cNombre: string;
    nCantidad: number;
    estatus: number;
}

export interface EstatusMesa {
    nEstatus: number;
    cEstatus: string;
    cColorEstatus: string;
    bDisponible: boolean;
    bWarning: boolean;
    bOcupadoColor: boolean;
}

export interface PreferenciaModal {
    nPreferencia: number;
    cPreferencia: string;
}

export interface ColorCapacidad {
    nMinimoCapacidad: number;
    cColorCapacidad: string;
}

export interface ConfiguracionesRespuesta {
    EstatusMesas: EstatusMesa[];
    Preferencias: PreferenciaModal[];
    ColoresCapacidad: ColorCapacidad[];
}

export interface AsignaMesaResponse {
	bConfirmacion: boolean,
	cMSG: string
};

export interface SelectValues {
    label: string;
    value: number;
}

export enum QUERY_KEYS {
    CONFIGURACIONES,
    CLIENTES_ESPERA,
    CLIENTE_FORM,
    INDICADORES_NAV,
    AREAS_RESTAURANTE,
    MESAS_RESTAURANTE,
}

export enum FILTROS_LISTA {
    EN_ESPERA = '1',
    NO_ENCONTRADOS = '0',
    ASIGNADOS = '2',
}

export enum MUTACIONES_TEXTO {
    Registra_ClienteEspera = 'Agregando cliente',
    Registra_AsignaMesa = 'Asignando mesas',
    Modifica_ClienteNoEncontrado = 'Moviendo cliente',
    Modifica_EliminaCliente = 'Eliminando cliente',
    Modifica_ClienteEspera = 'Regresando cliente a espera',
    Modifica_DeshacerAsignacion = 'Deshaciendo Asignación',
    Modifica_LiberarMesa = 'Liberando mesas',
    Modifica_Cliente = 'Modificando Cliente',
}

export enum ERRORES {
    ASIGNA_MESA_NO_ID = "Necesitas crear un cliente",
    ASIGNA_MESA_NO_MESAS = "Necesitas seleccionar mesas",
}

export const NOMBRE_LISTA: Record<keyof typeof FILTROS_LISTA | FILTROS_LISTA, string> = {
    ASIGNADOS: 'Clientes Asignados',
    '2': 'Clientes Asignados',

    EN_ESPERA: 'Clientes en Espera',
    '1': 'Clientes en Espera',

    NO_ENCONTRADOS: 'Clientes no encontrados',
    '0': 'Clientes no encontrados',
};

export interface PlantaSeccionLoading {
    mesas: null[]
}

export interface IPlantaRestauranteLoading {
    nombre: string;
    secciones: PlantaSeccionLoading[]
}