import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Divider,
    Popover,
    PopoverContent,
    PopoverTrigger,
    useDisclosure,
} from '@nextui-org/react';
import { IconWrapper } from '../IconWrapper';
import { TrashRemove } from '@/assets';

interface props {
    onClick: () => void
}

export const EliminarCliente = ({
    onClick
}: props) => {
    const { isOpen, onClose, onOpenChange } = useDisclosure({
        defaultOpen: false,
    })

    return (
        <Popover
            triggerType="dialog"
            placement="bottom"
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            classNames={{
                content: 'p-0',
                base: 'min-w-48',
            }}
        >
            <PopoverTrigger>
                <button className="flex w-full items-center justify-start gap-2 px-2 py-1.5 outline-none">
                    <div>
                        <IconWrapper className="bg-danger-100 text-danger-600">
                            <TrashRemove width={20} height={20} />
                        </IconWrapper>
                    </div>

                    <span className="w-full text-start">Eliminar Cliente</span>
                </button>
            </PopoverTrigger>
            <PopoverContent>
                <Card className="w-full">
                    <CardHeader>Confirmación</CardHeader>
                    <Divider />
                    <CardBody>¿Estás seguro de eliminar este cliente?</CardBody>
                    <Divider />
                    <CardFooter className="flex justify-end gap-2">
                        <Button size="sm" color="default" onClick={onClose}>
                            Cancelar
                        </Button>

                        <Button
                            size="sm"
                            color="danger"
                            onClick={() => {
                                onClick();
                            }}
                        >
                            Eliminar
                        </Button>
                    </CardFooter>
                </Card>
            </PopoverContent>
        </Popover>
    );
};
