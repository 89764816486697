import {
    CapacidadMesaPlanta,
    DistribucionMesasPlanta,
    EstatusMesa,
    MesaRestaurante,
    MesasRespuesta,
    PlantaSeccionLoading,
    SeccionRestaurante,
} from '@/types';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es-mx';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.locale('es-mx');
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

export const crearPlantaLoading = (nColumnas = 6, nFilas = 6) => {
    const mesaInfoArray: PlantaSeccionLoading[] = [];

    let nGrupo = 0;
    for (let i = 0; i < nFilas; i++, nGrupo++) {
        const mesaArray: null[] = [];
        for (let j = 0; j < Math.floor(Math.random() * nColumnas) + 1; j++) {
            mesaArray.push(null);
        }
        mesaInfoArray.push({
            mesas: mesaArray,
        });
    }
    return mesaInfoArray;
};

export const getMesasSeleccionadas = (areas: MesasRespuesta, mesasSeleccionadas: string[]) => {
    const resultado: MesaRestaurante[] = [];

    Object.values(areas).forEach((area) => {
        area.forEach((seccion) => {
            seccion.mesas.forEach((mesa) => {
                if (mesasSeleccionadas.includes(mesa.nMesa.toString())) {
                    resultado.push(mesa);
                }
            });
        });
    });

    return resultado;
};

export const getDisponibilidadPlanta = (planta: SeccionRestaurante[], estatus: EstatusMesa[] | undefined) => {
    const capacidadMesaPlanta: Record<number, CapacidadMesaPlanta> = {};

    planta.forEach((seccion) => {
        seccion.mesas.forEach(({ nCapacidadComensales, nEstatusMesa }) => {
            const estatusMesa = estatus?.find((value) => value.nEstatus === nEstatusMesa);

            if (!capacidadMesaPlanta[nCapacidadComensales]) {
                capacidadMesaPlanta[nCapacidadComensales] = { nCapacidad: nCapacidadComensales, nLibres: 0 };
            }

            if (estatusMesa?.bDisponible) {
                capacidadMesaPlanta[nCapacidadComensales].nLibres++;
            }
        });
    });

    return Object.values(capacidadMesaPlanta).sort((a, b) => a.nCapacidad - b.nCapacidad);
};

export const getDistribucionPlanta = (planta: SeccionRestaurante[], estatus: EstatusMesa[] | undefined) => {
    const distribucionMesaPlanta: Record<string, DistribucionMesasPlanta> = {};

    planta.forEach((seccion) => {
        seccion.mesas.forEach(({ nEstatusMesa }) => {
            const estatusMesa = estatus?.find((value) => value.nEstatus === nEstatusMesa) ?? {
                nEstatus: -1,
                cEstatus: 'No definido',
            };

            if (!distribucionMesaPlanta[estatusMesa.nEstatus]) {
                distribucionMesaPlanta[estatusMesa.nEstatus] = {
                    cNombre: estatusMesa.cEstatus,
                    nCantidad: 0,
                    estatus: nEstatusMesa,
                };
            }

            distribucionMesaPlanta[estatusMesa.nEstatus].nCantidad++;
        });
    });

    return Object.values(distribucionMesaPlanta);
};

export const getRelativeTime = (date: string) => {
    return dayjs(
        dayjs(date)
            .subtract(dayjs().utcOffset(), 'minutes')
            .toISOString(),
    ).fromNow()
}

export const convertToAMPM = (time: string): string => {
    // Verifica si el formato de la hora es HH:MM
    if (!/^\d{2}:\d{2}$/.test(time)) {
        return 'Indefinido';
    }

    const [hours, minutes] = time.split(':');
    let hoursNumber = parseInt(hours, 10);
    const minutesNumber = parseInt(minutes, 10);

    // Verifica que los valores de las horas y minutos sean válidos
    if (hoursNumber < 0 || hoursNumber > 23 || minutesNumber < 0 || minutesNumber > 59) {
        return 'Indefinido';
    }

    const period = hoursNumber >= 12 ? 'PM' : 'AM';
    hoursNumber = hoursNumber % 12 || 12; // Convierte 00:00 a 12:00 AM y 12:00 a 12:00 PM

    const hoursString = hoursNumber.toString().padStart(2, '0');
    const minutesString = minutesNumber.toString().padStart(2, '0');

    return `${hoursString}:${minutesString} ${period}`;
}

export const compararMesasRespuestas = (
    mesasAntiguas: MesasRespuesta,
    mesasNuevas: MesasRespuesta,
) => {
    const diferencias: Record<string, Record<string, unknown>> = {};

    const plantasAntigua = Object.keys(mesasAntiguas);
    const plantasNueva = Object.keys(mesasNuevas);

    const plantas = new Set([...plantasAntigua, ...plantasNueva]);

    plantas.forEach((planta) => {
        const plantaAntigua = mesasAntiguas[planta] || [];
        const plantaNueva = mesasNuevas[planta] || [];

        const mesasAntigua: Record<string, MesaRestaurante> = {};
        plantaAntigua.forEach((s) =>
            s.mesas.forEach((m) => {
                mesasAntigua[`${m.nSeccion}-${m.nMesa}`] = m;
            }),
        );

        const mesasNueva: Record<string, MesaRestaurante> = {};
        plantaNueva.forEach((s) =>
            s.mesas.forEach((m) => {
                mesasNueva[`${m.nSeccion}-${m.nMesa}`] = m;
            }),
        );

        const todasMesas = new Set([...Object.keys(mesasAntigua), ...Object.keys(mesasNueva)]);

        todasMesas.forEach((mesaKey) => {
            const mesaAntigua = mesasAntigua[mesaKey];
            const mesaNueva = mesasNueva[mesaKey];

            if (!mesaAntigua) {
                diferencias[planta] = diferencias[planta] || {};
                diferencias[planta][mesaKey] = { diferencia: 'Nueva mesa añadida', mesa: mesaNueva };
            } else if (!mesaNueva) {
                diferencias[planta] = diferencias[planta] || {};
                diferencias[planta][mesaKey] = { diferencia: 'Mesa eliminada', mesa: mesaAntigua };
            } else {
                const diferenciaMesa = Object.keys(mesaAntigua).reduce(
                    (dif, key) => {
                        if (mesaAntigua[key as keyof MesaRestaurante] !== mesaNueva[key as keyof MesaRestaurante]) {
                            dif[key] = {
                                antiguo: mesaAntigua[key as keyof MesaRestaurante],
                                nuevo: mesaNueva[key as keyof MesaRestaurante],
                            };
                        }
                        return dif;
                    },
                    {} as Record<string, unknown>,
                );

                if (Object.keys(diferenciaMesa).length > 0) {
                    diferencias[planta] = diferencias[planta] || {};
                    diferencias[planta][mesaKey] = diferenciaMesa;
                }
            }
        });
    });

    return diferencias;
}
