import { ClienteEspera, FILTROS_LISTA, NOMBRE_LISTA } from '@/types';
import { Card, CardBody, Skeleton } from '@nextui-org/react';
import { UserGroup, UserIcon } from '@/assets';
import { ClienteEsperaItem } from '../ClienteEsperaItem';
import { ClientesEsperaLoading } from '../Loading/ClientesEsperaLoading';

interface props {
    clientesEspera: ClienteEspera[] | undefined;
    nFiltroPor: FILTROS_LISTA;
    isLoading?: boolean;
}

export const ClienteEsperaList = ({ clientesEspera, nFiltroPor, isLoading }: props) => {
    const personasTotal = clientesEspera?.reduce((acc, clienteEspera) => acc + clienteEspera?.nPersonas, 0);

    return (
        <div className="flex max-h-screenWithouthNavbar min-w-clienteItem w-full flex-shrink-0 flex-col">
            <Card className="relative z-20 w-full flex-shrink-0 select-none rounded-none shadow-lg">
                <CardBody className="flex flex-row items-center justify-between py-2 font-semibold">
                    <p className="text-md">{NOMBRE_LISTA[nFiltroPor]}</p>

                    <div className="flex min-h-[50px] flex-row items-center gap-4">
                        {isLoading ? (
                            <>
                                <Skeleton className="h-6 w-16 rounded-md" />
                                <Skeleton className="h-6 w-11 rounded-md" />
                            </>
                        ) : (
                            <>
                                <div className="flex items-center gap-2">
                                    <UserGroup height={50} width={50} />
                                    {clientesEspera?.length}
                                </div>

                                <div className="flex items-center gap-2">
                                    <UserIcon width={25} height={25} />
                                    {personasTotal}
                                </div>
                            </>
                        )}
                    </div>
                </CardBody>
            </Card>

            <div
                className={`relative z-10 w-full flex-1 flex-shrink flex-grow ${isLoading ? 'overflow-y-hidden' : 'overflow-y-auto'} bg-content1`}
            >
                {isLoading
                    ? [...Array(20)].map((_, index) => <ClientesEsperaLoading key={index} />)
                    : Array.isArray(clientesEspera) &&
                      clientesEspera.map((cliente, orden) => (
                          <ClienteEsperaItem {...cliente} orden={(orden + 1).toString()} key={orden} />
                      ))}
            </div>
        </div>
    );
};
