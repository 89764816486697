import { useEffect, useState } from 'react';

interface hook {
    isPressed: boolean;
    isTouchedBeforeTimeout: boolean;
    setIsPressed: React.Dispatch<React.SetStateAction<boolean>>;
    handleStart: () => void;
    handleEnd: () => void;
    handlers: {
        onPointerDown: React.PointerEventHandler<HTMLElement>;
        onPointerUp: React.PointerEventHandler<HTMLElement>;
        onPointerLeave: React.PointerEventHandler<HTMLElement>;

        onMouseDown: React.MouseEventHandler<HTMLElement>;
        onMouseOut: React.MouseEventHandler<HTMLElement>;
        onMouseUp: React.MouseEventHandler<HTMLElement>;
    };
}

interface props {
    timeout: number;
    closeDelay: number;
    handleTouchedBeforeTimeout?: () => void;
    handlePressed?: () => void;
}

export const useLongPress = ({
    closeDelay = 1000,
    timeout = 1000,
    handlePressed,
    handleTouchedBeforeTimeout,
}: props): hook => {
    const [isPressed, setIsPressed] = useState(false);
    const [isTouchedBeforeTimeout, setIsTouchedBeforeTimeout] = useState(false);

    let pressTimer: NodeJS.Timeout | null = null;
    let leaveTimer: NodeJS.Timeout;

    useEffect(() => {
        if (isTouchedBeforeTimeout) {
            setIsTouchedBeforeTimeout(false);
        }
    }, [isTouchedBeforeTimeout]);

    const handleStart = () => {
        clearTimeout(leaveTimer);
        setIsTouchedBeforeTimeout(false);

        pressTimer = setTimeout(() => {
            setIsPressed(true);
            handlePressed && handlePressed();
            pressTimer = null;
        }, timeout);
    };

    const handleEnd = () => {
        if (pressTimer) {
            clearTimeout(pressTimer);
            setIsTouchedBeforeTimeout(true);
            handleTouchedBeforeTimeout && handleTouchedBeforeTimeout();
            pressTimer = null;
        }

        leaveTimer = setTimeout(() => setIsPressed(false), closeDelay);
    };

    return {
        isPressed,
        handleStart,
        handleEnd,
        isTouchedBeforeTimeout,
        handlers: {
            onMouseDown: (e) => e.button === 0 && handleStart(),
            onMouseOut: handleEnd,
            onMouseUp: handleEnd,

            onPointerDown: (e) => e.button === 0 && handleStart(),
            onPointerLeave: handleEnd,
            onPointerUp: handleEnd,
        },
        setIsPressed,
    };
};
