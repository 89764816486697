import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';

import { useEffect, useRef, useState } from 'react';
import { Card, CardBody, CardHeader, Skeleton } from '@nextui-org/react';
import { crearPlantaLoading } from '@/utils';
import { useWindowSize } from '@/hooks/useWindowSize';
import { useScreenOrientation } from '@/hooks/useScreenOrientation';

export const PlantaLoading = () => {
    const [secciones] = useState(crearPlantaLoading(50, 10));
    const wrapperRef = useRef<ReactZoomPanPinchRef | null>(null);
    const mesasContainer = useRef<HTMLDivElement>(null);

    const orientation = useScreenOrientation();
    const windowSize = useWindowSize();
    const [scale, setScale] = useState(1);

    const adjustScale = () => {
        const wrapperComp = wrapperRef?.current?.instance.wrapperComponent;

        if (!mesasContainer.current || !wrapperComp) {
            return;
        }

        wrapperRef.current?.centerView?.();
        wrapperRef.current?.resetTransform?.();

        const widthRatio = (wrapperComp.offsetWidth - 32) / mesasContainer.current.offsetWidth;
        const heightRatio = (wrapperComp.offsetHeight - 32) / mesasContainer.current.offsetHeight;

        setScale(Math.min(widthRatio, heightRatio));
    };

    useEffect(() => {
        setTimeout(() => {
            adjustScale();
        }, 300);
    }, [orientation, windowSize]);

    useEffect(() => {
        adjustScale();
    }, []);

    return (
        <div className="relative h-full bg-zinc-100">
            <TransformWrapper
                minScale={0.9}
                maxScale={5}
                ref={wrapperRef}
                centerZoomedOut
                centerOnInit
                limitToBounds={false}
            >
                <TransformComponent
                    wrapperClass="!w-full !h-full relative"
                    contentClass="!h-full !w-full justify-center items-center"
                >
                    <div
                        style={{ transform: `scale(${scale})` }}
                        className="flex h-full w-full items-center justify-center"
                    >
                        <div ref={mesasContainer} className="flex flex-wrap justify-start gap-6">
                            {secciones.map((seccion, keyC) => (
                                <Card key={keyC}>
                                    <CardHeader className="pb-0">
                                        <Skeleton className="h-6 w-24 rounded-lg" />
                                    </CardHeader>
                                    <CardBody className={`grid grid-flow-col grid-rows-3 content-start gap-3`}>
                                        {seccion.mesas.map((_, key) => (
                                            <Skeleton key={key} className="size-12 rounded-lg" />
                                        ))}
                                    </CardBody>
                                </Card>
                            ))}
                        </div>
                    </div>
                </TransformComponent>
            </TransformWrapper>

            <Skeleton className="absolute right-2 top-2 size-12 rounded-lg" />

            <Skeleton className="absolute bottom-4 right-4 size-12 rounded-lg" />

            <Skeleton className="absolute bottom-4 right-1/2 h-10 w-32 translate-x-16 rounded-lg" />
        </div>
    );
};
