import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "@/context";
import { PagesRoutes } from "@/types";
import { Spinner } from "@nextui-org/react";
import { handleRedirectSSO } from "@/utils/Auth";

const Component = () => {
	const { handleValidarOTP, isLogged } = useContext(AuthContext);
	const navigate = useNavigate();
	const { otp } = useParams();

	useEffect(() => {
		if (!otp && isLogged === null) {
			handleRedirectSSO();
		} else if (isLogged === null && otp) {
			const [cDeviceToken, cOTP] = otp.split('-');

			handleValidarOTP(cOTP || '', cDeviceToken || '');
		} else if (isLogged) {
			navigate(PagesRoutes.ASIGNAR_MESAS);
		} else {
			handleRedirectSSO();
		}
	}, [otp, isLogged]);

	return (
		<div className="h-screen w-screen flex justify-center items-center">
			<Spinner size="lg" label="Validando sesión..." color="primary" />
		</div>
	)
}

export { 
	Component,
	Component as ValidarOTP
}