import { useForm } from "react-hook-form";
import { AsignaMesaResponse, ERRORES, FormAsignarMesa, MUTACIONES_TEXTO, QUERY_KEYS } from "@/types";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API_URL, simpleFetch } from "@/utils/Fetch";
import { useNotify } from "./useNotify";

interface props {
	closeModalAsignarMesa: () => void
}

export const useFormAsignarMesa = ({ closeModalAsignarMesa } : props) => {
	const queryClient = useQueryClient();
    const { notifyAsync, notifyError, notifySuccess } = useNotify();
	const { mutate: submitAsignarMesa, isPending: isSubmittingAsignarMesa, data: responseAsignarMesa, error: errorAsignarMesa } = useMutation<AsignaMesaResponse | string, string[] | string, FormAsignarMesa>({
		mutationFn: async ({ cliente, mesas, bConfirmado }) => {
			const nIDCliente = cliente?.[0];

			if (isNaN(Number(nIDCliente))) {
				throw ERRORES.ASIGNA_MESA_NO_ID;
			}

			if (!Array.isArray(mesas) || mesas.length === 0) {
				throw ERRORES.ASIGNA_MESA_NO_MESAS;
			}

			const response = await notifyAsync(() => {
				return simpleFetch<AsignaMesaResponse | string>({
					method: 'POST',
					url: `${API_URL}/Registra/AsignarMesa`,
					params: {
						nIDCliente: nIDCliente,
						cMesasSeleccionadas: mesas.join(','),
						bConfirmado
					},
				});
			}, MUTACIONES_TEXTO.Registra_AsignaMesa);

			if (!response.success) {
				throw response.data
			}

			return response.data;
		},
		onSuccess: (data) => {
			if (typeof data !== 'string') {
				return;
			}

			closeModalAsignarMesa();
			resetAsignarMesa();
			queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CLIENTES_ESPERA] });
			queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.MESAS_RESTAURANTE] });

			notifySuccess(data);
		},
		onError: notifyError
	});

	const { 
		control: controlAsignarMesa, 
		handleSubmit, 
		reset: resetAsignarMesa, 
		resetField: resetFieldAsignarMesa,
		watch: watchAsignarMesa,
		setValue: setValueAsignarMesa,
	} = useForm<FormAsignarMesa>({
		defaultValues: {
			cliente: null,
			mesas: [],
			planta: null,
			bConfirmado: false
		}
	});

	const handleSubmitAsignarMesa = handleSubmit((values) => submitAsignarMesa(values));
	const fieldValuesAsignarMesa = watchAsignarMesa();

    return {
		controlAsignarMesa, 
		handleSubmitAsignarMesa, 
		resetAsignarMesa, 
		resetFieldAsignarMesa, 
		fieldValuesAsignarMesa, 
		isSubmittingAsignarMesa,
		setValueAsignarMesa,
		responseAsignarMesa,
		errorAsignarMesa
	};
};
