import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Divider,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SelectItem,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow,
    useDisclosure,
} from '@nextui-org/react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { LineaEsperaContext } from '@/context';
import { UserIcon } from '@/assets';
import { getMesasSeleccionadas } from '@/utils';
import { FILTROS_LISTA } from '@/types';
import { BaseFormModal, SelectInput } from 'nextui-utils';

interface props {
    isOpen: boolean;
    onOpenChange: () => void;
}

const clienteColumnas = [
    {
        key: 2,
        label: 'Nombre',
    },
    {
        key: 4,
        label: 'Personas',
    },
    {
        key: 5,
        label: 'Preferencia',
    },
    {
        key: 3,
        label: 'Hora Registro',
    },
];

const mesasColumnas = [
    {
        key: 1,
        label: 'Codigo Mesa',
    },
    {
        key: 2,
        label: 'Capacidad',
    },
    {
        key: 3,
        label: 'Planta',
    },
];

export const ModalAsignarMesa = ({ isOpen, onOpenChange }: props) => {
    const {
        handleSubmitAsignarMesa,
        controlAsignarMesa,
        setValueAsignarMesa,
        fieldValuesAsignarMesa: { cliente, mesas, planta },
        isSubmittingAsignarMesa,
        areasRestaurante,

        responseAsignarMesa,

        isLoadingClientesEspera,
        clientesEspera,

        mesasRestaurante,

        initCreateFormCliente,
        isOpenModalFormCliente,

        setNFiltroPor,
        nFiltroPor,
    } = useContext(LineaEsperaContext);

    const [hasOpenedAgregarCliente, setHasOpenedAgregarCliente] = useState(false);
    const handleOpenAgregarCliente = () => {
        initCreateFormCliente();
        setHasOpenedAgregarCliente(true);
    };

    useEffect(() => {
        if (!isOpenModalFormCliente && hasOpenedAgregarCliente) {
            setHasOpenedAgregarCliente(false);
        }

        if (isOpen && nFiltroPor === FILTROS_LISTA.ASIGNADOS) {
            setNFiltroPor([FILTROS_LISTA.EN_ESPERA]);
        }

        if (isOpen && nFiltroPor === FILTROS_LISTA.NO_ENCONTRADOS && !clientesEspera?.length) {
            setNFiltroPor([FILTROS_LISTA.EN_ESPERA]);
        }
    }, [isOpen, isOpenModalFormCliente]);

    const plantaSeleccionada = areasRestaurante
        ? areasRestaurante.find((val) => planta === val.nArea)?.cArea
        : 'No definido';

    const clienteEncontrado = clientesEspera?.find((clienteEspera) => clienteEspera.nID === cliente?.[0]);
    const shouldRenderAgregarCliente = !useMemo(
        () => clientesEspera?.find((clienteEspera) => clienteEspera.nID === cliente?.[0]),
        [isOpen],
    );
    const mesasSeleccionadas = getMesasSeleccionadas(mesasRestaurante ?? {}, mesas);

    const {
        isOpen: isOpenConfirmacion,
        onClose: onCloseConfirmacion,
        onOpen: onOpenConfirmacion,
    } = useDisclosure({
        defaultOpen: false,
    });

    useEffect(() => {
        if (!isOpen) {
            return onCloseConfirmacion();
        }
    }, [isOpen]);

    useEffect(() => {
        if (responseAsignarMesa && typeof responseAsignarMesa !== 'string') {
            onOpenConfirmacion();
        }
    }, [responseAsignarMesa]);

    return (
        <BaseFormModal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            title="Confirmar Asignación mesa"
            handleSubmit={handleSubmitAsignarMesa}
            isSubmitting={isSubmittingAsignarMesa}
            footerExtraButton={
                <Button color="secondary" onClick={handleOpenAgregarCliente}>
                    Agregar Cliente
                </Button>
            }
            SubmitButton={
                <Popover
                    isOpen={isOpenConfirmacion}
                    classNames={{
                        content: 'p-0',
                    }}
                    placement="bottom"
                    size="sm"
                >
                    <PopoverTrigger>
                        <Button
                            color="primary"
                            type="submit"
                            onClick={() => {
                                setValueAsignarMesa('bConfirmado', false);
                            }}
                            isLoading={isSubmittingAsignarMesa}
                        >
                            Confirmar
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                        <form onSubmit={handleSubmitAsignarMesa}>
                            <Card className="w-full">
                                <CardHeader>Confirmación</CardHeader>
                                <Divider />
                                <CardBody>
                                    <p className="max-w-64 text-pretty">
                                        {typeof responseAsignarMesa !== 'string' && responseAsignarMesa?.cMSG}
                                    </p>
                                </CardBody>
                                <Divider />
                                <CardFooter className="flex justify-end gap-2">
                                    <Button size="sm" color="default" onClick={onCloseConfirmacion}>
                                        Cancelar
                                    </Button>

                                    <Button
                                        size="sm"
                                        color="primary"
                                        type="submit"
                                        isLoading={isSubmittingAsignarMesa}
                                        onClick={() => {
                                            setValueAsignarMesa('bConfirmado', true);
                                        }}
                                    >
                                        Confirmar
                                    </Button>
                                </CardFooter>
                            </Card>
                        </form>
                    </PopoverContent>
                </Popover>
            }
            ModalProps={{
                isDismissable: !hasOpenedAgregarCliente,
            }}
        >
            {shouldRenderAgregarCliente && Array.isArray(clientesEspera) && !!clientesEspera.length ? (
                <div className="flex gap-2">
                    <SelectInput
                        control={controlAsignarMesa}
                        label="Cliente"
                        placeholder="Seleccione un cliente"
                        labelPlacement="outside"
                        name="cliente"
                        required
                        isLoading={isLoadingClientesEspera}
                        startContent={<UserIcon />}
                        maxSelect={1}
                    >
                        {clientesEspera?.map((cliente) => (
                            <SelectItem
                                key={cliente.nID}
                                textValue={`${cliente.cNombre} - ${cliente.nPersonas} persona${cliente.nPersonas !== 1 ? 's' : ''}`}
                                value={cliente.nID}
                            >
                                <div className="flex justify-between">
                                    {cliente.cNombre}

                                    <div className="flex items-center gap-2">
                                        <UserIcon width={16} height={16} />
                                        {cliente.nPersonas}
                                    </div>
                                </div>
                            </SelectItem>
                        ))}
                    </SelectInput>
                </div>
            ) : <></>}

            {clienteEncontrado ? (
                <Table removeWrapper aria-label="Tabla Cliente">
                    <TableHeader columns={clienteColumnas}>
                        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
                    </TableHeader>
                    <TableBody>
                        <TableRow key={`${clienteEncontrado.nID}-${Math.random()}`}>
                            <TableCell>{clienteEncontrado.cNombre}</TableCell>
                            <TableCell>{clienteEncontrado.nPersonas}</TableCell>
                            <TableCell>{clienteEncontrado.cPreferencia || 'Ninguna'}</TableCell>
                            <TableCell>{clienteEncontrado.cHoraRegistro}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            ) : <></>}

            {Array.isArray(mesasSeleccionadas) ? (
                <Table removeWrapper aria-label="Tabla Cliente" classNames={{ emptyWrapper: 'h-24' }}>
                    <TableHeader columns={mesasColumnas}>
                        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
                    </TableHeader>
                    <TableBody emptyContent={'Sin Mesas Seleccionadas'}>
                        {mesasSeleccionadas.map((mesa) => (
                            <TableRow key={`${mesa.cCodigoMesa}-${Math.random()}`}>
                                <TableCell>{mesa.cCodigoMesa}</TableCell>
                                <TableCell>{mesa.nCapacidadComensales}</TableCell>
                                <TableCell>{plantaSeleccionada}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : <></>}
        </BaseFormModal>
    );
};
