import { useEffect, useState } from 'react';

export function useLocalStorage<Type>(
    key: string,
    defaultValue: Type,
    revalidateInterval?: number,
): [Type, (value: Type) => void] {
        // this method update our localStorage and our state
        const setLocalStorageStateValue = (value: Type) => {
            localStorage.setItem(key, JSON.stringify(value));
            setLocalStorageValue(value);
        };
    
        // this method gets the value from localStorage
        const getLocalStorageValue = () => {
            try {
                const value = localStorage.getItem(key);
                // If value is already present in
                // localStorage then return it
    
                // Else set default value in
                // localStorage and then return it
                if (value) {
                    return JSON.parse(value) as Type;
                } else {
                    localStorage.setItem(key, JSON.stringify(defaultValue));
                    return defaultValue;
                }
            } catch (error) {
                localStorage.setItem(key, JSON.stringify(defaultValue));
                return defaultValue;
            }
        };

    // Create state variable to store
    // localStorage value in state
    const [localStorageValue, setLocalStorageValue] = useState<Type>(getLocalStorageValue());

    useEffect(() => {
        if (revalidateInterval) {
            const interval = setInterval(() => {
                getLocalStorageValue();
            }, revalidateInterval);

            return () => {
                clearInterval(interval);
            }
        }
    }, [revalidateInterval]);

    return [localStorageValue, setLocalStorageStateValue];
}
