import { ClienteEspera, MesaRestaurante, MUTACIONES_TEXTO, QUERY_KEYS } from "@/types";
import { API_URL, simpleFetch } from "@/utils/Fetch";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNotify } from "./useNotify";

export const useLineaEsperaAcciones = () => {
	const queryClient = useQueryClient();
    const { notifyAsync, notifyError, notifySuccess } = useNotify();
    const { mutate: handleClienteNoEncontrado } = useMutation<string, string[], ClienteEspera['nID']>({
        mutationFn: async (nIDCliente) => {
            const response = await notifyAsync(() => {
                return simpleFetch<string>({
                    method: 'POST',
                    url: `${API_URL}/Modifica/ClienteNoEncontrado`,
                    params: {
                        nIDCliente,
                    },
                });
            }, MUTACIONES_TEXTO.Modifica_ClienteNoEncontrado);

            if (!response.success) {
                throw response.data;
            }

            return response.data;
        },
        onSuccess: (msg) => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CLIENTES_ESPERA] });

            notifySuccess(msg);
        },
        onError: notifyError,
    });

    const { mutate: handleEliminarCliente } = useMutation<string, string[], ClienteEspera['nID']>({
        mutationFn: async (nIDCliente) => {
            const response = await notifyAsync(() => {
                return simpleFetch<string>({
                    method: 'POST',
                    url: `${API_URL}/Modifica/EliminaCliente`,
                    params: {
                        nIDCliente,
                    },
                });
            }, MUTACIONES_TEXTO.Modifica_EliminaCliente);

            if (!response.success) {
                throw response.data;
            }

            return response.data;
        },
        onSuccess: (msg) => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CLIENTES_ESPERA] });

            notifySuccess(msg);
        },
        onError: notifyError,
    });

    const { mutate: handleMoverClienteEspera } = useMutation<string, string[], ClienteEspera['nID']>({
        mutationFn: async (nIDCliente) => {
            const response = await notifyAsync(() => {
                return simpleFetch<string>({
                    method: 'POST',
                    url: `${API_URL}/Modifica/ClienteMoverEspera`,
                    params: {
                        nIDCliente,
                    },
                });
            }, MUTACIONES_TEXTO.Modifica_ClienteEspera);

            if (!response.success) {
                throw response.data;
            }

            return response.data;
        },
        onSuccess: (msg) => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CLIENTES_ESPERA] });

            notifySuccess(msg);
        },
        onError: notifyError,
    });

    const { mutate: handleDeshacerAsignacion } = useMutation<string, string[], MesaRestaurante['nMesa']>({
        mutationFn: async (nMesa) => {
            const response = await notifyAsync(() => {
                return simpleFetch<string>({
                    method: 'POST',
                    url: `${API_URL}/Modifica/DeshacerAsignacion`,
                    params: {
                        nMesa,
                    },
                });
            }, MUTACIONES_TEXTO.Modifica_DeshacerAsignacion);

            if (!response.success) {
                throw response.data;
            }

            return response.data;
        },
        onSuccess: (msg) => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CLIENTES_ESPERA] });
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.MESAS_RESTAURANTE] });

            notifySuccess(msg);
        },
        onError: notifyError,
    });

    const { mutate: handleLiberarMesa } = useMutation<string, string[], MesaRestaurante['nMesa']>({
        mutationFn: async (nMesa) => {
            const response = await notifyAsync(() => {
                return simpleFetch<string>({
                    method: 'POST',
                    url: `${API_URL}/Modifica/LiberarMesa`,
                    params: {
                        nMesa,
                    },
                });
            }, MUTACIONES_TEXTO.Modifica_LiberarMesa);

            if (!response.success) {
                throw response.data;
            }

            return response.data;
        },
        onSuccess: (msg) => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.MESAS_RESTAURANTE] });

            notifySuccess(msg);
        },
        onError: notifyError,
    });

    return {
		handleClienteNoEncontrado,
		handleDeshacerAsignacion,
		handleEliminarCliente,
		handleLiberarMesa,
		handleMoverClienteEspera
	};
};
