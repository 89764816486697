import {
    Button,
    Chip,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow,
    Tabs,
} from '@nextui-org/react';
import { SeccionRestaurante } from '@/types';
import { getDisponibilidadPlanta, getDistribucionPlanta } from '@/utils';
import { useContext } from 'react';
import { LineaEsperaContext } from '@/context';
import { DashboardSolid } from '@/assets/icons';

interface props {
    secciones: SeccionRestaurante[];
}

const columnsDisponibles = [
    {
        key: 'Capacidad',
        label: 'Capacidad',
    },
    {
        key: 'Disponibles',
        label: 'Mesas',
    },
];

const columnsDistribucion = [
    {
        key: 'Tipo',
        label: 'Tipo',
    },
    {
        key: 'Cantidad',
        label: 'Mesas',
    },
];

export const DisponibilidadInfo = ({ secciones }: props) => {
    const { ColoresCapacidad, estatus } = useContext(LineaEsperaContext);

    const disponibilidadPlanta = getDisponibilidadPlanta(secciones, estatus).filter((seccion) => seccion.nLibres > 0);
    const distribucionPlanta = getDistribucionPlanta(secciones, estatus);

    let disabledTabs: string[] = [];

    if (!disponibilidadPlanta.length) {
        disabledTabs = [...disabledTabs, 'disponibles'];
    }

    if (!distribucionPlanta.length) {
        disabledTabs = [...disabledTabs, 'distribucion'];
    }

    return (
        <Popover
            placement="bottom-end"
            classNames={{
                content: ['p-0'],
            }}
        >
            <PopoverTrigger>
                <Button color="primary" isIconOnly variant='shadow' size='lg' radius='lg' className="absolute right-2 top-2">
                    <DashboardSolid width={34.6} height={34.6} />
                </Button>
            </PopoverTrigger>

            <PopoverContent>
                <Tabs classNames={{
                    panel: 'px-0 py-0',
                    base: 'justify-start flex w-full',
                    tabList: 'w-full',
                    tab: 'p-0'
                }} aria-label="Options" disabledKeys={disabledTabs}>
                    <Tab key="disponibles" title="Disponibles">
                        <Table aria-label="Listado de Mesas disponibles">
                            <TableHeader columns={columnsDisponibles}>
                                {(column) => <TableColumn key={column.key}>{column.label.toUpperCase()}</TableColumn>}
                            </TableHeader>
                            <TableBody>
                                {disponibilidadPlanta.map((plantaCap, key) => {
                                    const ColorCapacidad = ColoresCapacidad?.find((color) => color.nMinimoCapacidad <= plantaCap.nCapacidad);

                                    return (
                                        <TableRow key={key}>
                                            <TableCell>
                                                <Chip
                                                    classNames={{
                                                        base: 'border-0',
                                                        content: 'text-white font-medium',
                                                    }}
                                                    style={{ backgroundColor: ColorCapacidad?.cColorCapacidad }}
                                                    variant="bordered"
                                                >
                                                    {plantaCap.nCapacidad} persona
                                                    {plantaCap.nCapacidad !== 1 ? 's' : ''}
                                                </Chip>
                                            </TableCell>
                                            <TableCell>{plantaCap.nLibres}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Tab>

                    <Tab key="distribucion" title="Distribución">
                        <Table aria-label="Listado de Mesas disponibles">
                            <TableHeader columns={columnsDistribucion}>
                                {(column) => <TableColumn key={column.key}>{column.label.toUpperCase()}</TableColumn>}
                            </TableHeader>
                            <TableBody>
                                {distribucionPlanta.map((distribucion, key) => {
                                    const estatusValue = estatus?.find((value) => value.nEstatus === distribucion.estatus);

                                    return (
                                        <TableRow key={key}>
                                            <TableCell>
                                                <Chip
                                                    classNames={{
                                                        base: 'border-0',
                                                        content: 'text-white font-medium',
                                                    }}
                                                    variant="bordered"
                                                    style={{ backgroundColor: estatusValue?.cColorEstatus }}
                                                >
                                                    {distribucion.cNombre}
                                                </Chip>
                                            </TableCell>
                                            <TableCell>{distribucion.nCantidad}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Tab>
                </Tabs>
            </PopoverContent>
        </Popover>
    );
};
