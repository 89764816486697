import { Navbar, NavbarBrand, NavbarContent, NavbarItem, Skeleton } from '@nextui-org/react';
import { ForkKnifeCrossed, UserGroup, UserIcon } from '@/assets';
import { NavItemIcon } from './NavItemIcon';
import { IndicadoresNav } from '@/types';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { HourGlassTimer } from '@/assets/icons';

interface LayourNavbarProps {
    isLoading: boolean;
    indicadores?: IndicadoresNav;
}

export const LayoutNavbar = ({ isLoading, indicadores }: LayourNavbarProps) => {
    const [moment, setMoment] = useState(dayjs().format('hh:mm:ss A'));
    const day = dayjs().format('DD/MM/YYYY');

    useEffect(() => {
        const interval = setInterval(() => setMoment(dayjs().format('hh:mm:ss A')), 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <Navbar isBordered>
            <NavbarBrand className="gap-6">
                <img alt="Logo de Pánama" className="w-[65px]" src="/logoGrupoPanama.png" />
            </NavbarBrand>

            <NavbarContent className="hidden gap-6 md:flex" justify="center">
                {isLoading ? (
                    <>
                        <Skeleton className="h-8 w-16 rounded-md" />
                        <Skeleton className="h-8 w-12 rounded-md" />
                        <Skeleton className="h-8 w-20 rounded-md" />
                        <Skeleton className="h-8 w-20 rounded-md" />
                        <Skeleton className="h-8 w-20 rounded-md" />
                    </>
                ) : (
                    <>
                        <NavItemIcon
                            icon={<UserGroup height={50} width={50} />}
                            data={indicadores?.nGrupos}
                            description="Cantidad de Grupos"
                            offset={-5.5}
                        />

                        <NavItemIcon
                            icon={<UserIcon height={25} width={25} />}
                            data={indicadores?.nPersonas}
                            description="Cantidad de Personas"
                        />

                        <NavItemIcon
                            icon={<HourGlassTimer height={25} width={25} />}
                            data={indicadores?.cTiempoPromedioEspera}
                            description="Tiempo de espera promedio"
                        />

                        <NavItemIcon
                            icon={<ForkKnifeCrossed height={25} width={25} />}
                            data={`${indicadores?.nPorcOcupacionMesas}%`}
                            description="Porcentaje de ocupación de mesas"
                        />

                        <NavItemIcon
                            icon={<ForkKnifeCrossed height={25} width={25} />}
                            data={`${indicadores?.nPorcOcupacionPersonas}%`}
                            description="Porcentaje de ocupación de personas"
                        />

                        <NavItemIcon
                            icon={<ForkKnifeCrossed height={25} width={25} />}
                            data={indicadores?.nGruposEspera}
                            description="Grupos en espera"
                        />
                    </>
                )}
            </NavbarContent>

            <NavbarContent justify="end">
                <NavbarItem>
                    <p className="text-end font-bold text-inherit">
                        {day} <br /> {moment}
                    </p>
                </NavbarItem>
            </NavbarContent>
        </Navbar>
    );
};
