import { createContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { RightChevron } from '@/assets';
import { ModalFormCliente } from '@/components/ModaFormCliente';
import { ModalAsignarMesa } from '@/components/ModalAsignarMesa';
import { ContextoMesas, ERRORES } from '@/types';
import { useLayout } from '@/hooks/useLayout';
import { useFormAsignarMesa } from '@/hooks/useFormAsignarMesa';
import { LayoutNavbar } from '@/layout/Navbar';
import { LayoutSideBar } from '@/layout/SideBar';
import { ClienteEsperaList } from '@/components/ClienteEsperaList';
import { useLineaEsperaData } from '@/hooks/useLineaEsperaData';
import { useLineaEsperaAcciones } from '@/hooks/useLineaEsperaAcciones';
import { useFormCliente } from '@/hooks/useFormCliente';

export const LineaEsperaContext = createContext({} as ContextoMesas);

const Component = () => {
    const {
        isOpenSideBar,
        toggleSideBar,

        isOpenModalFormCliente,
        toggleModalFormCliente,
        openModalFormCliente,
        closeModalFormCliente,

        isOpenModalAsignarMesa,
        openModalAsignarMesa,
        toggleModalAsignarMesa,
        closeModalAsignarMesa,

        nFiltroPor,
        setNFiltroPor,
    } = useLayout();

    const {
        clientesEspera,
        isLoadingClientesEspera,

        indicadoresNavbar,
        isLoadingIndicadores,

        areasRestaurante,
        isLoadingAreas,

        isLoadingMesas,
        mesasRestaurante,
        shouldReloadPanel,

        ColoresCapacidad,
        estatus,
        estatusOcupado,
        isLoadingConfiguracion,
        preferencias
    } = useLineaEsperaData({
        nFiltroPor,
    });

    const {
        handleClienteNoEncontrado,
        handleDeshacerAsignacion,
        handleEliminarCliente,
        handleLiberarMesa,
        handleMoverClienteEspera,
    } = useLineaEsperaAcciones();

    const {
        controlAsignarMesa,
        handleSubmitAsignarMesa,
        resetAsignarMesa,
        resetFieldAsignarMesa,
        fieldValuesAsignarMesa,
        isSubmittingAsignarMesa,
        setValueAsignarMesa,
        responseAsignarMesa,
        errorAsignarMesa
    } = useFormAsignarMesa({
        closeModalAsignarMesa,
    });

    const {
        controlFormCliente,
        handleSubmitCliente,
        resetFormCliente,
        isSubmittingCliente,

        closeFormCliente,
        isFetchingClienteUpdate,
        initCreateFormCliente,
        initUpdateFormCliente,
    } = useFormCliente({
        closeModalFormCliente,
        openModalFormCliente,
        setValueAsignarMesa,
        isOpenModalAsignarMesa,
        setNFiltroPor,
        preferencias,
    });

    useEffect(() => {
        const { planta } = fieldValuesAsignarMesa;

        if (isNaN(Number(planta)) && areasRestaurante) {
            setValueAsignarMesa('planta', areasRestaurante[0].nArea);
        }
    }, [areasRestaurante, fieldValuesAsignarMesa.planta]);

    useEffect(() => {
        if (errorAsignarMesa === ERRORES.ASIGNA_MESA_NO_ID) {
            initCreateFormCliente();
        } else if (errorAsignarMesa === ERRORES.ASIGNA_MESA_NO_MESAS) {
            closeModalAsignarMesa();
        }
    }, [errorAsignarMesa])
    

    return (
        <LineaEsperaContext.Provider
            value={{
                isOpenSideBar,
        
                isOpenModalFormCliente,
                openModalFormCliente,
        
                isOpenModalAsignarMesa,
                openModalAsignarMesa,
                toggleModalAsignarMesa,
        
                nFiltroPor,
                setNFiltroPor,

                clientesEspera,
                isLoadingClientesEspera,

                areasRestaurante,
                isLoadingAreas,

                isLoadingMesas,
                mesasRestaurante,
                shouldReloadPanel,

                ColoresCapacidad,
                estatus,
                estatusOcupado,
                isLoadingConfiguracion,
                preferencias,

                handleClienteNoEncontrado,
                handleDeshacerAsignacion,
                handleEliminarCliente,
                handleLiberarMesa,
                handleMoverClienteEspera,

                controlAsignarMesa,
                handleSubmitAsignarMesa,
                resetAsignarMesa,
                resetFieldAsignarMesa,
                fieldValuesAsignarMesa,
                isSubmittingAsignarMesa,
                setValueAsignarMesa,
                responseAsignarMesa,

                controlFormCliente,
                handleSubmitCliente,
                resetFormCliente,
                isSubmittingCliente,

                closeFormCliente,
                isFetchingClienteUpdate,
                initCreateFormCliente,
                initUpdateFormCliente,
            }}
        >
            <LayoutNavbar indicadores={indicadoresNavbar} isLoading={isLoadingIndicadores} />

            <ModalFormCliente isOpen={isOpenModalFormCliente} onOpenChange={toggleModalFormCliente} />

            <ModalAsignarMesa isOpen={isOpenModalAsignarMesa} onOpenChange={toggleModalAsignarMesa} />

            <div className="fixed flex h-screenWithouthNavbar flex-row">
                <div
                    className={`absolute h-full overflow-y-hidden ${isOpenSideBar ? 'left-0' : '-left-sideBar'} w-sideBar flex-shrink-0 transition-all duration-300 ease-in-out`}
                >
                    <LayoutSideBar>
                        <ClienteEsperaList
                            clientesEspera={clientesEspera}
                            nFiltroPor={nFiltroPor}
                            isLoading={isLoadingClientesEspera}
                        />
                    </LayoutSideBar>
                </div>

                <button
                    type="button"
                    onClick={toggleSideBar}
                    className={`absolute top-1/2 flex h-12 w-6 items-center justify-center rounded-r-lg bg-blue-500 p-0 text-white ${isOpenSideBar ? 'left-sideBar' : 'left-0'} z-50 transition-all duration-300 ease-in-out`}
                >
                    <div className={`${isOpenSideBar && 'rotate-180'} transition-all duration-300 ease-in-out`}>
                        <RightChevron height={20} width={20} />
                    </div>
                </button>

                <div
                    className={`flex-shrink-2 relative h-full overflow-y-auto ${isOpenSideBar ? 'left-sideBar w-screenWithouthSideBar' : 'left-0 w-screen'} transition-all duration-300 ease-in-out`}
                >
                    <Outlet />
                </div>
            </div>
        </LineaEsperaContext.Provider>
    );
};

export {
    Component,
    Component as ListaEsperaLayout
}