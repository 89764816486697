import {
    ClienteForm,
    FILTROS_LISTA,
    FORM_STATE,
    FormAsignarMesa,
    MUTACIONES_TEXTO,
    QUERY_KEYS,
    SelectValues,
} from '@/types';
import { API_URL, simpleFetch } from '@/utils/Fetch';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UseFormSetValue, useForm, useWatch } from 'react-hook-form';
import { useNotify } from './useNotify';
import { useEffect } from 'react';

interface props {
    closeModalFormCliente: () => void;
    setValueAsignarMesa: UseFormSetValue<FormAsignarMesa>;
    setNFiltroPor: React.Dispatch<React.SetStateAction<FILTROS_LISTA[]>>;
    openModalFormCliente: () => void
    isOpenModalAsignarMesa: boolean
    preferencias: SelectValues[] | undefined;
}

interface ResponseAgregarCliente {
    msg: string;
    idCliente: string;
}

interface ResponseObtenerCliente extends Omit<ClienteForm, 'nID' | 'formState' | 'aPreferencia' | 'bRegistro'> {
    nPreferencia: number
    bSinRegistro: boolean
}

export const useFormCliente = ({ 
    closeModalFormCliente, 
    setValueAsignarMesa, 
    setNFiltroPor, 
    preferencias, 
    isOpenModalAsignarMesa, 
    openModalFormCliente, 
}: props) => {
    const queryClient = useQueryClient();
    const { notifyAsync, notifyError, notifySuccess } = useNotify();

    const {
        control: controlFormCliente,
        handleSubmit,
        setValue: setValueFormCliente,
        reset,
        setFocus
    } = useForm<ClienteForm>({
        defaultValues: {
            bRegistro: true,
            bClienteEspecial: false,
            nPersonas: '1',
            cNombre: '',
            cTelefono: '',
            aPreferencia: [preferencias?.[0]?.value?.toString()],
            cNotas: '',
            formState: FORM_STATE.CREATE,
            nID: '',
        },
        mode: "all",
    });

    const resetFormCliente = () => {
        reset({
            bRegistro: true,
            bClienteEspecial: false,
            nPersonas: '1',
            cNombre: '',
            cTelefono: '',
            aPreferencia: [preferencias?.[0]?.value?.toString()],
            cNotas: '',
            formState: FORM_STATE.CREATE,
            nID: '',
        });
    }

    const { formState, nID } = useWatch({
        control: controlFormCliente,
    });

    const { isPending: isSubmittingCliente, mutate } = useMutation<ResponseAgregarCliente | string, string[], ClienteForm>({
        mutationFn: async ({ aPreferencia, bRegistro, cNombre, cNotas, nPersonas, cTelefono, bClienteEspecial, formState, nID }) => {
            let response;

            if (formState === FORM_STATE.UPDATE) {
                response = await notifyAsync(() => {
                    return simpleFetch<string>({
                        method: 'POST',
                        url: `${API_URL}/Modifica/Cliente`,
                        params: {
                            nIDCliente: nID,
                            nPreferencia: aPreferencia?.[0],
                            cNombre,
                            cNotas,
                            nPersonas,
                            cTelefono,
                            bSinRegistro: !bRegistro,
                            bClienteEspecial,
                        },
                    });
                }, MUTACIONES_TEXTO.Modifica_Cliente);
            } else {
                response = await notifyAsync(() => {    
                    return simpleFetch<ResponseAgregarCliente>({
                        method: 'POST',
                        url: `${API_URL}/Registra/ClienteEspera`,
                        params: bRegistro ? {
                            nPreferencia: aPreferencia[0],
                            cNombre,
                            cNotas,
                            nPersonas,
                            cTelefono,
                            bSinRegistro: !bRegistro,
                            bClienteEspecial,
                        } : {
                            bSinRegistro: !bRegistro,
                            nPersonas,
                        },
                    });
                }, MUTACIONES_TEXTO.Registra_ClienteEspera);
            }

            if (!response.success) {
                throw response.data;
            }

            return response.data;
        },
        onSuccess: (response) => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CLIENTES_ESPERA] });
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CLIENTE_FORM, nID] });
            resetFormCliente();
            closeModalFormCliente();

            if (typeof response === 'string') {
                notifySuccess(response);
                return;
            }
        
            // Asignar como cliente elegido al cliente agregado
            isOpenModalAsignarMesa && setValueAsignarMesa('cliente', [response.idCliente]);
            setNFiltroPor([FILTROS_LISTA.EN_ESPERA]);
            notifySuccess(response.msg);
        },
        onError: notifyError,
    });

    const { 
        isFetching: isFetchingClienteUpdate, 
        data: clienteUpdateData
    } = useQuery({
        queryKey: [QUERY_KEYS.CLIENTE_FORM, nID],
        enabled: formState === FORM_STATE.UPDATE && !!nID,
        staleTime: Infinity,
        queryFn: async () => {
            const response = await notifyAsync(() => {
                return simpleFetch<ResponseObtenerCliente>({
                    url: `${API_URL}/Obten/Cliente/${nID}`,
                    method: 'GET',
                })
            }, 'Obteniendo información de cliente a editar...');

            if (!response.success) {
                throw response.data;
            }

            return response.data;
        },
    });

    useEffect(() => {
        if (!clienteUpdateData) {
            return;
        }

        const { bClienteEspecial, bSinRegistro, cNombre, cNotas, cTelefono, nPersonas, nPreferencia } = clienteUpdateData;
        setValueFormCliente('aPreferencia', [nPreferencia.toString()]);
        setValueFormCliente('bClienteEspecial', bClienteEspecial);
        setValueFormCliente('bRegistro', !bSinRegistro);
        setValueFormCliente('cNombre', cNombre);
        setValueFormCliente('cNotas', cNotas);
        setValueFormCliente('cTelefono', cTelefono);
        setValueFormCliente('nPersonas', nPersonas);
    }, [clienteUpdateData]);

    const initUpdateFormCliente = (nID: ClienteForm['nID']) => {
        setValueFormCliente('nID', nID);
        setValueFormCliente('formState', FORM_STATE.UPDATE);
        openModalFormCliente();
    };

    const initCreateFormCliente = (nPersonas?: string) => {
        resetFormCliente();
        openModalFormCliente();
        nPersonas && setValueFormCliente('nPersonas', nPersonas);
        nPersonas && setFocus('cNombre');
    }

    const closeFormCliente = () => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CLIENTE_FORM, nID] })
        resetFormCliente();
        closeModalFormCliente();
    }

    useEffect(() => {
        if (preferencias) {
            setValueFormCliente('aPreferencia', [preferencias?.[0]?.value?.toString()]);
        }
    }, [preferencias]);

    const handleSubmitCliente = handleSubmit((values: ClienteForm) => mutate(values));

    return {
        isSubmittingCliente,
        controlFormCliente,
        handleSubmitCliente,
        resetFormCliente,
        isFetchingClienteUpdate,
        initUpdateFormCliente,
        initCreateFormCliente,
        closeFormCliente,
    };
};
