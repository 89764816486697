import { LineaEsperaContext } from '@/context';
import { FILTROS_LISTA, NOMBRE_LISTA } from '@/types';
import { Select, SelectItem } from '@nextui-org/react';
import { useContext } from 'react';

// const ListadoPaginas = [
//     {
//         name: 'Lista de Espera',
//         path: PagesRoutes.LISTA_ESPERA,
//     },
//     {
//         name: 'Asignación de Mesas',
//         path: PagesRoutes.ASIGNAR_MESAS,
//     },
// ];

const opcionesFiltrado = [
    {
        description: NOMBRE_LISTA.EN_ESPERA,
        key: FILTROS_LISTA.EN_ESPERA,
    },
    {
        description: NOMBRE_LISTA.NO_ENCONTRADOS,
        key: FILTROS_LISTA.NO_ENCONTRADOS,
    },
    {
        description: NOMBRE_LISTA.ASIGNADOS,
        key: FILTROS_LISTA.ASIGNADOS,
    },
];

export const LayoutSideBar = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
    const { nFiltroPor, setNFiltroPor } = useContext(LineaEsperaContext);

    return (
        <div className="flex h-full flex-col gap-2">
            <Select
                label="Filtrado por: "
                variant="underlined"
                radius="none"
                selectedKeys={nFiltroPor}
                classNames={{ trigger: 'h-[68px] min-h-[68px] !px-4' }}
                selectionMode='single'
                onSelectionChange={(values) => values !== 'all' && setNFiltroPor([...values] as FILTROS_LISTA[])}
            >
                {opcionesFiltrado.map((filtro) => (
                    <SelectItem key={filtro.key} value={filtro.key}>
                        {filtro.description}
                    </SelectItem>
                ))}
            </Select>

            {children}
        </div>
    );
};
