import {
    Card,
    CardBody,
    CardHeader,
    Divider,
    Link,
    Listbox,
    ListboxItem,
    ListboxSection,
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@nextui-org/react';
import { ClienteEspera, FILTROS_LISTA } from '@/types';
import { CheckboxChecked, CheckboxWrong, UserIcon } from '@/assets';
import { useContext, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { LineaEsperaContext } from '@/context';
import { Indicator } from './Indicator';
import { IconWrapper } from '../IconWrapper';
import { getRelativeTime } from '@/utils';
import { CircleMove, CircleStar, EditIcon, NoteIcon } from '@/assets/icons';
import { EliminarCliente } from './EliminarCliente';

interface props extends ClienteEspera {
    orden: string;
}

export const ClienteEsperaItem = ({
    nPersonas,
    cHoraRegistro,
    cNombre,
    orden,
    cPreferencia,
    nID,
    cTelefono,
    ...cliente
}: props) => {
    const {
        controlAsignarMesa,
        handleClienteNoEncontrado,
        handleMoverClienteEspera,
        handleEliminarCliente,
        initUpdateFormCliente,
        nFiltroPor,
        ColoresCapacidad,
    } = useContext(LineaEsperaContext);
    const [isOpen, setIsOpen] = useState(false);

    const Color = useMemo(
        () => ColoresCapacidad?.find((color) => color.nMinimoCapacidad <= nPersonas),
        [nPersonas, ColoresCapacidad],
    );

    return (
        <Controller
            control={controlAsignarMesa}
            name="cliente"
            render={({ field: { value, onChange } }) => (
                <Popover
                    triggerScaleOnOpen={false}
                    color="default"
                    backdrop="opaque"
                    shadow="lg"
                    triggerType="menu"
                    placement="bottom"
                    isOpen={isOpen}
                    onOpenChange={(open) => setIsOpen(open)}
                >
                    <PopoverTrigger>
                        <Card
                            className={`w-full min-w-clienteItem cursor-pointer select-none rounded-none ${value?.[0] === nID && 'dark'}`}
                        >
                            <CardBody className="flex flex-row items-center gap-3 text-center text-sm font-medium">
                                <Indicator personas={nPersonas} orden={orden} />

                                <div className="line-clamp-2 flex-grow">
                                    <p>{cNombre}</p>
                                </div>

                                <div className="flex flex-row items-center justify-between gap-2">
                                    <UserIcon width={18} height={18} />
                                    <p>{nPersonas}</p>
                                </div>

                                <div className="line-clamp-2 w-20 flex-grow-[2] text-center">
                                    <p>{cPreferencia ?? 'Ninguna'}</p>
                                </div>

                                <div className="w-[50px] h-full flex flex-col">
                                    <p className='text-center'>{cHoraRegistro}</p>

                                    <div className='w-full justify-center flex gap-1'>
                                        {
                                            cliente.bClienteEspecial && <CircleStar height={16} width={16} />
                                        }
                                        {
                                            cliente.cNotas && <NoteIcon height={16} width={16} />
                                        }
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </PopoverTrigger>

                    <PopoverContent className="flex w-64 flex-col gap-4 py-4">
                        <div
                            style={{ backgroundColor: Color?.cColorCapacidad }}
                            className="w-full items-start rounded-lg p-2 text-start text-white"
                        >
                            <div className="flex gap-4">
                                <div className="flex flex-row items-center justify-between gap-2">
                                    <UserIcon width={18} height={18} />
                                    <p className="text-large font-medium">{nPersonas}</p>
                                </div>
                                <div>
                                    <p className="text-base">{cNombre}</p>
                                    <p className="text-small">{cPreferencia}</p>
                                </div>
                            </div>
                            {cliente.bClienteEspecial ? (
                                <div className='flex justify-between mt-2'>
                                    <CircleStar height={16} width={16} />

                                    <p className="text-tiny">{getRelativeTime(cliente.dFecha_Registro)}</p>
                                </div>
                            ) : (
                                <p className="text-tiny text-end mt-2">{getRelativeTime(cliente.dFecha_Registro)}</p>
                            )}
                        </div>

                        {cliente.cNotas && (
                            <Card
                                classNames={{
                                    base: 'w-full',
                                }}
                            >
                                <CardHeader>Notas</CardHeader>
                                <Divider />
                                <CardBody>{cliente.cNotas}</CardBody>
                            </Card>
                        )}

                        {nFiltroPor !== FILTROS_LISTA.ASIGNADOS && (
                            <Listbox classNames={{ base: 'p-0' }} aria-label="Listado de Opciones">
                                <ListboxSection classNames={{ base: 'mb-0' }} title="Acciones">
                                    {value?.[0] !== nID ? (
                                        <ListboxItem
                                            key="asignarMesa"
                                            startContent={
                                                <IconWrapper className="bg-success-100 text-success-600">
                                                    <CheckboxChecked width={20} height={20} />
                                                </IconWrapper>
                                            }
                                            onClick={() => {
                                                setIsOpen(false);
                                                onChange([nID]);
                                            }}
                                        >
                                            Asignar Mesa
                                        </ListboxItem>
                                    ) : (
                                        <ListboxItem
                                            key="deseleccionarMesa"
                                            startContent={
                                                <IconWrapper className="bg-warning-100 text-warning-600">
                                                    <CheckboxWrong width={20} height={20} />
                                                </IconWrapper>
                                            }
                                            onClick={() => {
                                                setIsOpen(false);
                                                onChange(null);
                                            }}
                                        >
                                            Deseleccionar mesa
                                        </ListboxItem>
                                    )}

                                    {nFiltroPor === FILTROS_LISTA.EN_ESPERA ? (
                                        <ListboxItem
                                            key="noEncontrado"
                                            startContent={
                                                <IconWrapper className="bg-primary-100 text-primary-600">
                                                    <CircleMove width={20} height={20} />
                                                </IconWrapper>
                                            }
                                            onClick={() => {
                                                handleClienteNoEncontrado(nID);
                                                setIsOpen(false);
                                            }}
                                        >
                                            Cliente No Encontrado
                                        </ListboxItem>
                                    ) : (
                                        <ListboxItem
                                            key="moverListaEspera"
                                            startContent={
                                                <IconWrapper className="bg-secondary-100 text-secondary-600">
                                                    <CircleMove width={20} height={20} />
                                                </IconWrapper>
                                            }
                                            onClick={() => {
                                                handleMoverClienteEspera(nID);
                                                
                                                setIsOpen(false);
                                            }}
                                        >
                                            Mover a Lista de Espera
                                        </ListboxItem>
                                    )}

                                    <ListboxItem
                                        key="editarCliente"
                                        startContent={
                                            <IconWrapper className="bg-warning-100 text-warning-600">
                                                <EditIcon width={20} height={20} />
                                            </IconWrapper>
                                        }
                                        onClick={() => {
                                            setIsOpen(false);
                                            initUpdateFormCliente(nID);
                                        }}
                                    >
                                        Editar Cliente
                                    </ListboxItem>

                                    <ListboxItem 
                                        classNames={{
                                            base: 'py-0 px-0'
                                        }} 
                                        key="eliminarCliente" 
                                        textValue='Eliminar Cliente'
                                    >
                                        <EliminarCliente 
                                            onClick={() => {
                                                handleEliminarCliente(nID);
                                                setIsOpen(false);
                                            }}
                                        />
                                    </ListboxItem>
                                </ListboxSection>
                            </Listbox>
                        )}

                        {cTelefono && (
                            <div className="flex w-full items-center justify-end">
                                <Link size="sm" href={`tel:${cTelefono}`}>
                                    Llamar: {cTelefono}
                                </Link>
                            </div>
                        )}
                    </PopoverContent>
                </Popover>
            )}
        />
    );
};
