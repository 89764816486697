import { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthContext } from '@/context';

const Component = () => {
	const { handleValidarSesion, isLogged } = useContext(AuthContext);

	useEffect(() => {
		isLogged === null && handleValidarSesion();
	}, []);

	return (
		<Outlet />
	);
}

export {
	Component,
	Component as ProtectedRoute
}