import { toast } from "sonner"

type notifyAsyncFunction = <T>(cb: () => Promise<T>, msg?: string) => Promise<T>

export const useNotify = () => {
	const notifyError = (err: string | string[]) => {
		if (typeof err === 'string') {
			toast.error(err);

			return;
		}

		// Itera en caso de que no sea un texto
		err.forEach(el => {
			toast.error(el);
		});
	}

	const notifySuccess = (message: string) => {
		toast.success(message);
	}

	const notifyAsync: notifyAsyncFunction = async (cb, msg = 'Cargando') => {
		const toastId = toast.loading(msg);

		const result = await cb();

		toast.dismiss(toastId);

		return result;
	};

	return {
		notifyError,
		notifySuccess,
		notifyAsync
	}
}